<template>
<div class="items shadow UserItem">
    <div class="all">
    <div class="menuItem">
        <router-link :to="'degreeinfo/'+item.userId">
            <img class="menuIcon" :src="(item.image?($store.state.thumbnail+item.image):('/man.png'))">
        </router-link>
        <v-btn :to="'degreeinfo/'+item.userId" class="btn-kacheb btn-service">جزئیات</v-btn>

        <div class="menutext">
            <router-link :to="'degreeinfo/'+item.userId">
                        <span class="textHeader">
                        {{item.fName}} {{item.lName}}<img class="confirm" v-if="item.confirmed>0" src="../../assets/confirm.png"/>
                        </span>
            </router-link>
            <span class="textfooter">
                {{item.tell}}
                        </span>
            <v-chip small :color="bgStatus.color"  style="color: #fff">{{bgStatus.label}} </v-chip>

        </div>

    </div>
    <div class="orderDate">{{item.updateTime|momentDate }}
    <br>

        <img :src="$store.state.smallthumbnail+item.license" style="width: 30px;margin: 3px">

        <img :src="$store.state.smallthumbnail+item.idCard"  style="width: 30px;margin: 3px">

        <img :src="$store.state.smallthumbnail+item.cardFace"  style="width: 30px;margin: 3px">


    </div>

    </div>

    <div v-if="item.desc"  style="text-align: right;margin-right: 55px;margin-bottom: 20px;font-size: 0.8em;color: #555">
        <span :style="{color:bgStatus.color}">
          {{item.desc}}
        </span>
    </div>

<div style="text-align: right;margin-right: 50px;margin-bottom: 20px">



</div>


</div>
</template>

<script>
    import moment from "moment-jalaali";
    import {mdiCellphoneAndroid } from '@mdi/js'

    export default {

        data(){
            return{
            icons:{mdiCellphoneAndroid }
            }
        },

        filters:{
            momentDate(date){
                return moment.unix(date).locale('fa').format('jYYYY/jMM/jDD');
            },
            momentDay(date){
                return moment.unix(date).locale('fa').format('dddd');
            },
        },
        props:['item'],
        methods:{

        },

        computed: {
            bgStatus() {
                if (this.item.confirmed == 0 && this.item.pending == 0) {
                    return {
                        color: '#ff1e28',
                        label: 'رد شده',
                    };
                }
                if (this.item.confirmed > 0) {
                    return {
                        color: '#008c55',
                        label: 'تایید شده',
                    };

                }
                if (this.item.confirmed == 0 && this.item.pending == 1) {
                    return {
                        color: '#ff8905',
                        label: 'منتظر بررسی',
                    };
                }
                return {
                    color: '#525755',
                    label: 'ارسال نشده',
                }
            }
        },

    }
</script>

<style scoped>

    .menuItem {
        display: flex;
        justify-content: right;
        text-align: right;
        width: 300px;
    }
    .all{
        display: flex;
        justify-content: space-between;
        text-align: right;
        vertical-align: middle;
    }
    .menuIcon {
        height: 50px;
        margin: -5px 10px 10px 10px;
    }

   .items{
       position: relative;
       background-color: #fff;
        border-radius: 20px;
        padding: 20px;
        margin: 20px 15px;
       color: #444;
       transition: 0.2s;
    }

   .items:hover{
       background-color: #f9f9f9;

   }

    .btn-service{
        position: absolute;
        bottom: -20px;
        left: 20px;
    }

    .menutext {
        display: inline-block;
    }

    .textfooter {
        display: block;
        font-size: 12px;
    }
    .status{
        display: inline-block;
        border-radius: 30px;
        padding: 5px;
        font-size: 12px;
        color: #222;
        height: 30px;
        border: #eee 1px solid;
    }
    .orderDate{
        color: #222;
        font-size: 13px;
        text-align: left;
    }
    .UserItem{
        transition: 0.2s;
    }

    .UserItem:hover{
        box-shadow: 0px 0px 10px #ccc;
    }

</style>