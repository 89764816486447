<template>
    <v-app>
        <v-card  id="app" flat :disabled="globalLoading" :loading="globalLoading">
            <div id="header">
                <div style="float: right">
                    <Drawer></Drawer>
                </div>


                <CurrentTime></CurrentTime>

                <div>
                    <MainMenu/>
                    <check-ref v-if="loggedIn"></check-ref>
                    <transition name="slide" mode="out-in">
                        <router-view/>
                    </transition>
                </div>
            </div>
        </v-card>
        <Notify/>
    </v-app>
</template>

<script>
    import {VApp} from 'vuetify/lib'
    import MainMenu from './components/MainMenu';
    import Drawer from './components/Drawer';
    import Notify from "@/components/Notify";
    import axios from "axios";
    import CheckRef from "@/components/CheckRef";
    import CurrentTime from "@/components/CurrentTime";

    export default {


        created() {
            if (this.loggedIn) {
                axios.get(this.$store.state.admin + 'adminaccess?userId=' + this.$store.state.userId).then(response => {
                    if(response.data.data.admin) {
                        localStorage.setItem('aa',1);this.$store.state.adminRole =1;}else {localStorage.setItem('aa',0);this.$store.state.adminRole =0;}
                    if(response.data.data.access) {
                        localStorage.setItem('a',1);this.$store.state.access =1;}else {localStorage.setItem('a',0);this.$store.state.access =0;}
                    if(!this.$store.state.access){
                        // this.logout();
                    }
                });
            }
        },
        methods:{
            logout() {
                localStorage.clear();
                this.$store.state.userId = null;
                this.$router.push('/add');
            },
        },
        computed: {


                loggedIn() {
                    return this.$store.state.userId;
                },
            globalLoading() {
                return this.$store.state.loading;
            }, snackbar() {
                return this.$store.state.notify;
            },
        },
        name: 'App',
        components: {
            CurrentTime,
            CheckRef,
            Notify,
            MainMenu,
            Drawer,
            VApp
        }
    }
</script>
<style>

    @import './assets/css/styleAwe.css';
    @import './assets/css/style.css';

</style>
<style>
    body {
        font-family: "Shabnam FD", Tahoma, Arial, sans-serif !important;
        margin: 0px !important;
        background-color: #f5f5f5;
    }


    .routeProgress {
        z-index: 1000;
        height: 5px;
        width: 100%;
        background-color: #cccccc;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }

    .routeProgressBar {
        transition: 1s;
        height: 100%;
        background-color: #007aff;
        position: relative;
        left: 0;
    }

    #app {
        background-color: #f5f5f5;
        font-family: "Shabnam FD", Tahoma, Arial, sans-serif !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        position: relative;
    }
</style>

<style>
    .slide-leave-active {
        transition: opacity 0.1s ease;
        opacity: 0;
        animation: slide-out 0.1s ease-out forwards;
    }

    .slide-leave {
        opacity: 1;
        transform: translateX(0);
    }

    .slide-enter-active {
        animation: slide-in 0.1s ease-out forwards;
    }

    @keyframes slide-out {
        0% {
            transform: translateY(0);
        }
        100% {
            transform: translateY(-30px);
        }
    }

    @keyframes slide-in {
        0% {
            transform: translateY(-30px);
        }
        100% {
            transform: translateY(0);
        }
    }
</style>
