<template>
    <span>
        <v-btn rounded color="warning" @click="AddDialog=!AddDialog">+
            افزودن
        {{roleTitle}}
        </v-btn>

        <v-dialog v-model="AddDialog" width="620">
            <v-card :loading="loading" :disabled="loading" v-if="AddDialog" style="padding-top: 20px">
                <h3>افزودن استادکار</h3>
                <div class="filters">
                    <div class="filterField">
                        <v-text-field clearable :rules="[tellRules.required, tellRules.pattern]"
                                      v-model="addProvicerForm.tell" label="شماره تماس"></v-text-field>
                    </div>
                    <div class="filterField">
                        <v-text-field :rules="[rules.required]" v-model="addProvicerForm.fName"
                                      label="نام"
                                      clearable/>
                    </div>
                    <div class="filterField">
                        <v-text-field :rules="[rules.required]" v-model="addProvicerForm.lName"
                                      label="نام خانوادگی"
                                      clearable/>
                    </div>


                    <div class="filterText">
                        <v-textarea rows="2" filled v-model="addProvicerForm.desc"
                                    label="توضیحات"
                                    clearable/>
                    </div>
                    <div class="filterText">
                        <v-textarea rows="2" filled v-model="addProvicerForm.address"
                                    label="آدرس"
                                    clearable/>
                    </div>

                </div>


                <div class="filterField">
                    <v-btn rounded color="success" style="margin: 10px" @click="addProvider">+
                        افزودن
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>
    </span>
</template>

<script>

    import axios from "axios";
    import AWN from "awesome-notifications";

    export default {


        components: {},
        created() {

        },
        props:['role','roleTitle'],
        data() {
            return {
                notify: new AWN(this.$store.state.NotifyOption),
                addProvicerForm: {
                    fName: '',
                    lName: '',
                    tell: '',
                    address: '',
                    desc: '',
                    creatorId: this.$store.state.userId,
                    role: this.role,
                },
                loading: false,
                AddDialog: false,
                tellRules: {
                    required: value => !!value ||
                        'شماره تماس را وارد کنید',
                    pattern: value => {
                        const pattern = /^(\+98|0)?9\d{9}$/
                        return pattern.test(value) || 'شماره تماس نا معتبر است'
                    },
                },
                rules: {
                    required: value => !!value ||
                        'وارد کردن این فیلد الزامی است',
                },

            }
        },

        methods: {
            addProvider() {
                if (!this.numberValidate) {
                    this.notify.alert('شماره تماس معتبری وارد کنید');
                } else if (!this.rules.required(this.addProvicerForm.fName)) {
                    this.notify.alert(' نام را وارد کنید');
                } else if (!this.rules.required(this.addProvicerForm.lName)) {
                    this.notify.alert('نام خانوادگی را وارد کنید');
                } else {
                    this.loading = true;
                    axios.post(this.$store.state.admin + 'createuser', this.addProvicerForm).then(response => {
                        if (response.data.result) {
                            this.notify.success(response.data.message);
                            this.$router.push('profiledetail/' + response.data.data.id);
                            this.addProvicerForm = {
                                fName: '', lName: '', tell: '', address: '', desc: '',
                                creatorId: this.$store.state.userId,
                                role: this.role,
                            };
                            this.notify.success(response.data.data);
                        }else {
                            this.notify.alert(response.data.message);
                            if (response.data.data) {
                                this.$router.push('profiledetail/' + response.data.data.id);
                            }
                        }
                        this.loading = false;
                    })
                }
            },

        },
        computed: {

            numberValidate() {
                return this.tellRules.pattern(this.addProvicerForm.tell) === true && this.tellRules.required(this.addProvicerForm.tell) === true;
            },
        },
    }
</script>

<style scoped>

    .filters {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        width: 600px;
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    .filterField {
        width: 180px !important;
    }

    .filterText {
        width: 270px !important;
    }

</style>