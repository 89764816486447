<template>
    <div>

        <template v-if="items!=null">
            <v-row justify="center">
                <v-dialog
                        v-model="$store.state.refDialog"
                        scrollable
                        max-width="600px"
                >
                    <v-card>
                        <v-card-title>پیگیری های انجام نشده</v-card-title>
                        <v-divider></v-divider>
                        <v-card-text >

                            <div v-for="(item,index) in items" :key="index">
                                <check-ref-order-item style="z-index: 1000" @closeDialog="dialog=false" :item="item"></check-ref-order-item>
                                <next-ref v-if="refId==item.id" @get="get" :id="item.id" :ref-time="item.refTime"></next-ref>
                                <v-btn class="btnRef" v-else  color="success" @click="refId=item.id">پیگیری</v-btn>

                            </div>

                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="$store.state.refDialog = false"
                            >
                                بستن
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </template>





    </div>
</template>
<script>
    import axios from "axios";
    import { VDialog,VCard } from 'vuetify/lib'
    import NextRef from "@/components/Orders/OrderDetail/NextRef";
    import CheckRefOrderItem from "@/components/CheckRefOrderItem";

    export default {
        computed: {
            refDialog() {
                return this.$store.state.refDialog;
            },
        },


        watch: {
            refDialog: function () {
                if(this.refDialog) {
                    this.get()
                }
            },

        },

        components:{
            CheckRefOrderItem,
            NextRef,

            VDialog,VCard,
        },
        data () {
            return {
                loading:false,
                dialog: false,
                refId:null,
                items:null,
            }
        },

        methods:{



            get(){
                this.loading=true;
                axios.post(this.$store.state.admin + 'checkref?userId='+this.$store.state.userId, {
                }).then(response => {
                    if(response.data.data){
                        this.items=response.data.data.data;
                        this.$store.state.refDialog=true;
                        this.$store.state.refCount=response.data.data.count;
                        this.loading=false;
                    }
                });
            }

        },





        created(){
            this.get();

        },
    }
</script>

<style scoped>
    .service{
        color: #ffaa00;

    }

    .btnRef{
        border-radius: 0px 0px 50px 50px;
        width: 50%;
        margin-top: -28px!important;
        margin-left: auto;
        margin-right: auto;
    }

    .img-small{
        max-width: 50%;
        margin: 0 auto!important;
    }
    .comment{
        padding: 40px!important;
    }
</style>