<template>
    <div class="main-box-shadow">
        <h3>افزودن شهر</h3>

        <v-autocomplete label="استان" :items="provinceList" item-text="name" @change="fly(province.lat,province.long)"
                        no-data-text="طالاعات بارگیری نشده" :item-value="function(item) {
                          return {'id':item.id,'name':item.name,'lat':item.lat,'long':item.long};
                        }" v-model="province"></v-autocomplete>

        <v-text-field v-model="item.name" label="عنوان شهر"></v-text-field>
      <v-row>
        <v-col cols="6"><v-text-field rounded filled label="شماره همراه" v-model="item.tell"></v-text-field></v-col>
        <v-col cols="6"><v-text-field rounded filled label="شماره ثابت" v-model="item.phone"></v-text-field></v-col>
      </v-row>
        <div>
            <mapir :zoom="zoom" class="map" ref="map" :apiKey="$store.state.mapApi"
                   @click="markerOnClick"
                   :center="coordinates">
                <mapAttributionControl/>
                <mapNavigationControl position="top-right"/>
                <mapGeolocateControl position="top-right"/>
                <mapMarker
                        :coordinates.sync="markerCoordinates"
                        color="none"
                />
            </mapir>
        </div>
        <v-btn class="btn-action" color="primary" @click="createcity" block rounded>افزودن</v-btn>
        <v-btn class="btn-action" color="error" to="/citylist" block rounded>لغو کردن</v-btn>
    </div>
</template>

<script>
    import axios from "axios";
    import AWN from "awesome-notifications";

    const mapNavigationControl = () => import( 'mapir-vue/src/components/UI/controls/NavigationControl');
    const mapGeolocateControl = () => import( 'mapir-vue/src/components/UI/controls/GeolocateControl');
    const mapAttributionControl = () => import( 'mapir-vue/src/components/UI/controls/AttributionControl');
    const mapMarker = () => import('mapir-vue/src/components/UI/Marker.vue');
    const mapir = () => import('mapir-vue/src/components/map/Map.vue');
    export default {
        name: "CreateCity",
        created() {
            axios.get(this.$store.state.api + 'getprovince').then(response => {
                if (response.data.result) {
                    this.provinceList = response.data.data;
                }
            })
        },
        data() {
            return {
                item: {
                    name: '',
                    province_id: null,
                },
                provinceList: [],
                province: {},
                zoom: 9,
                notify: new AWN(this.$store.state.NotifyOption),
                coordinates: [52.35804277783245, 36.47193069037883],
                markerCoordinates: [52.35804277783245, 36.47193069037883],
            }
        },


        methods: {

            createcity() {

                if(!this.province.id){
                  return   this.notify.alert('استان رو انتخاب کنید');
                }
                this.item.province_id = this.province.id;

                axios.post(this.$store.state.admin + 'createcity', this.item).then(response => {
                    if (response.data.result) {
                        this.notify.success('شهر با موفقیت اضافه شده');
                        this.$router.push('/citylist')
                    } else {
                        this.notify.alert('ناموفق');
                    }
                })
            },
            markerOnClick(event) {
                this.markerCoordinates = [event.actualEvent.lngLat.lng, event.actualEvent.lngLat.lat];
                this.item.lat = event.actualEvent.lngLat.lat;
                this.item.long = event.actualEvent.lngLat.lng;
            },
            fly(lat, long) {
                this.item.lat = lat;
                this.item.long = long;
                this.$refs.map.actions.flyTo({
                    center: [long, lat],
                    speed: 10,
                    zoom: 10
                }).then(res => {
                    this.markerCoordinates = [res.center.lng, res.center.lat];
                    this.item.lat = lat;
                    this.item.long = long;
                });
            },
        },
        components: {
            mapir,
            mapMarker,
            mapAttributionControl,
            mapNavigationControl,
            mapGeolocateControl,
        }
    }
</script>

<style scoped>
    .map {
        border-radius: 20px;
        overflow: hidden;
        border: 10px #fff solid;
        box-shadow: 0px 0px 10px #eee;
        width: 100%;
        min-height: 300px;
    }

    .btn-action {
        margin: 10px auto;
    }
</style>