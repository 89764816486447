<template>
    <span>
        <v-btn rounded color="warning" @click="AddDialog=!AddDialog">+
            افزودن
        هزینه
        </v-btn>

        <v-dialog v-model="AddDialog" width="620">
            <v-card :loading="loading" :disabled="loading" v-if="AddDialog" style="padding-top: 20px">
                <h3>افزودن هزینه</h3>
                            <v-form ref="addCost">
                <div class="filters">
                    <div class="filterField">
                        <v-text-field clearable :rules="[rules.required]"
                                      v-model="addForm.title" label="عنوان هزینه"></v-text-field>
                    </div>
                    <div class="filterField">
                        <v-text-field :rules="[rules.required]" v-model="addForm.fullName"
                                      label="نام و نام خانوادگی/شرکت/ارگان"
                                      clearable/>
                    </div>
                    <div class="filterField">


                        <v-text-field :hint="addForm.price|Num2persian" :rules="[rules.required,rules.number]" v-model="addForm.inputPrice"
                                      label="  مبلغ (تومان)"
                                      clearable/>




                    </div>


                    <div class="filterField">
                        <v-select rows="2" :rules="[rules.required]" :items="alltype" item-text="text"
                                  item-value="value" filled v-model="addForm.type"
                                  label="نوع"
                                  clearable/>
                    </div>
                    <div class="filterText">
                        <v-textarea rows="2" :rules="[rules.required]" filled v-model="addForm.desc"
                                    label="توضیحات"
                                    clearable/>
                    </div>

                </div>
                <div class="filterField">
                    <v-btn rounded color="success" style="margin: 10px" @click="addProvider">+
                        افزودن
                    </v-btn>
                </div>
                            </v-form>
            </v-card>
        </v-dialog>
    </span>
</template>

<script>

    import axios from "axios";
    import AWN from "awesome-notifications";
    import Num2persian from 'num2persian';

    export default {


        components: {},
        created() {


        },

        data() {
            return {
                notify: new AWN(this.$store.state.NotifyOption),
                addForm: {
                    title: '',
                    fullName: '',
                    desc: '',
                    inputPrice: 0,
                    price: 0,

                    type: '',
                },

                alltype: [{
                    text: 'حقوق',
                    value: 'salary'
                }, {
                    text: 'کرایه مکان',
                    value: 'rent'
                }, {
                    text: 'قبوض',
                    value: 'ghabz'
                }, {
                    text: 'هزینه جاری',
                    value: 'cost'
                }],

                loading: false,
                AddDialog: false,
                rules: {
                    required: value => !!value ||
                        'وارد کردن این فیلد الزامی است',
                    number: v  => {
                        if (!isNaN(parseFloat(v))) return true;
                        return 'عددی انگلیسی و معتبر وارد کنید';
                    },
                },



            }
        },

        filters: {
            /**
             * @return {string}
             */
            Num2persian: function (val) {
                if(val>0){
                    return  Num2persian(val)+' تومان ';
                }

            }
        },
        watch: {
            'addForm.inputPrice': function (newValue) {

                if(newValue){
                    this.addForm.inputPrice = newValue.replace(/\D/g, "")
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    this.addForm.price = this.addForm.inputPrice.replace(/\D/g, "");

                }else {
                    this.addForm.price = this.addForm.inputPrice;
                }

            }

        },


        methods: {


            get() {
                this.$emit('get');
                this.loading = false;
            },
            validate() {
                return this.$refs.addCost.validate();
            },

            addProvider() {
                if (this.validate()) {
                    this.loading = true;
                    axios.post(this.$store.state.admin + 'addcost', this.addForm).then(response => {
                        if (response.data.result) {
                            this.notify.success(response.data.message);
                            this.addForm = {
                                title: '',
                                fullName: '',
                                desc: '',
                                price: 0,
                                type: '',
                            };
                            this.AddDialog = false;
                            this.get();
                        } else {
                            this.notify.alert(response.data.message);
                        }
                        this.loading = false;
                    })

                }
            },

        },
        computed: {},
    }
</script>

<style scoped>

    .filters {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        width: 600px;
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    .filterField {
        width: 250px !important;
    }

    .filterText {
        width: 100% !important;
    }

</style>