<template>
    <div>


        <div>

            <div class="container-fliud shadow" :style="indexHeader">


                <div class="container">


                    <div class="maim-menu" v-if="loggedIn">
                        <template v-if="Width<600">

                            <v-spacer></v-spacer>
                            <div style="margin: 10px">
                                <v-btn text @click="$store.state.refDialog=true">
                                    <v-badge dense :value="$store.state.refCount" :content="$store.state.refCount">
                                        پیگیری
                                    </v-badge>
                                </v-btn>
                            </div>
                            <router-link to="/">
                                <img alt="بنر نوژا سرویس" class="logo" src="../../src/assets/logo.png">
                            </router-link>
                            <v-spacer></v-spacer>

                            <v-btn to="/cats" icon>
                                <v-icon>{{icon.mdiFormatListBulletedSquare}}</v-icon>
                            </v-btn>
                            <v-btn to="/allorders" icon>
                                <v-icon>{{icon.mdiCheckAll}}</v-icon>
                            </v-btn>
                            <v-btn to="/search" icon>
                                <v-icon>{{icon.mdiMagnify}}</v-icon>
                            </v-btn>
                            <v-btn to="/profile" icon>
                                <v-icon>{{icon.mdiAccount}}</v-icon>
                            </v-btn>



                        </template>

                        <template v-else>
                            <router-link to="/">
                                <img alt="بنر نوژا سرویس" class="logo" src="../../src/assets/logo.png">
                            </router-link>

                            <v-btn text class="singleMenu" to="/allorders">
                                <v-icon>{{icon.mdiCheckAll}}</v-icon>

                                <span class="menuText">
    همه ی سفارشات
  </span>
                            </v-btn>
                            <v-btn text class="singleMenu" to="/provider">
                                <v-icon>{{icon.mdiAccount}}</v-icon>

                                <span class="menuText">
    استادکاران
  </span>
                            </v-btn>
                            <v-btn text class="singleMenu" to="/search">
                                <v-icon>{{icon.mdiMagnify}}</v-icon>
                                <span class="menuText">
    جستجو
  </span> </v-btn>
                            <v-btn text class="singleMenu" to="/profile">
                                <v-icon>{{icon.mdiAccount}}</v-icon>
                                <span class="menuText">
   <span v-if="loggedIn">
   پروفایل
  </span>
                        <span v-else>
    ورود کنید
  </span>
                </span></v-btn>

                            <span class="singleMenu">
<b v-if="Admin">
<v-btn  color="success" to="/configs" >
      <v-icon small>{{icon.mdiCog }}</v-icon>
                                   تنظیمات

                               </v-btn>
                        <br>
                        </b>

                        <b>
                               <v-btn text @click="$store.state.refDialog=true">
                                   پیگیری
                                  <v-chip x-small color="primary" v-if="$store.state.refCount">{{$store.state.refCount}}</v-chip>

                               </v-btn>

                        </b>

                    </span>



                        </template>





                    </div>
                </div>

            </div>





        </div>

    </div>

</template>

<script>


    import { VIcon } from 'vuetify/lib'
    import { mdiPost,mdiMagnify,mdiAccount,mdiFormatListBulletedSquare,mdiPhone,mdiCheckAll,mdiCog } from '@mdi/js'

    export default {

        data() {
            return {
                drawer: true,
                icon: {mdiPost,mdiCheckAll,mdiMagnify,mdiAccount,mdiFormatListBulletedSquare,mdiPhone,mdiCog}
            }
        },
        methods:{

        },
        components:{
            VIcon
        },
        computed: {
            loggedIn() {
                return this.$store.state.userId;
            },
            /**
             * @return {boolean}
             */
            Admin() {
                return this.$store.state.adminRole;
            },
            Width(){
                return this.$store.state.Width;
            },
            indexHeader: function () {
                if (this.$route.name === 'Index') {
                    return {
                        boxShadow: 'unset!important',
                        border: 'none'
                    };
                } else {
                    return {}
                }
            },

        },
    }
</script>

<style scoped>

    @media screen and (max-width: 1000px) {
        .menuText {
            margin-top: 10px;
            font-size: 0.8em;
            display: block;
        }

    }

    .singleMenu{
        margin-top: auto;
        margin-bottom: auto;
    }
    @media screen and (max-width: 800px) {
        .menuText {
            margin-top: 7px;
            font-size: 0.8em;
            display: block;
        }

        .logo {
            height: 60px !important;
        }
        .maim-menu{
            height: 60px!important;
        }
    }


    @media screen and (max-width: 600px) {

        .menuText {
            margin-top: 5px;

            font-size: 0.8em;

        }

    }


    .menuText {
        vertical-align: top;
        top: 5px;
    }

    .maim-menu {
        position: relative;
        margin-right: auto;
        margin-left: auto;
        max-width: 1000px;
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        z-index: 10;
        height: 100px;
        margin-top: 0px;
    }

    .maim-menu a {
        color: #444;
    }

    .logo {
        height: 100px;
    }

    .Drawer{
        z-index: 10000000;
    }

    .v-list-item__title{
        font-size: 1em!important;
        margin: 20px;
    }


    .activeLink{
        background-color: #faffc9;
        color: #aa6720;
    }
    .DrawerLogo{
        width: 70%;
    }
    .contactUs{
        background-color: #faffc9;
        color: #aa6720;
        padding: 10px;

    }

    .contactIcon{
        color:#aa6720 ;
    }

    .topNav{
        width: 100%;
        background-color: #4c4d4b;
        border-bottom: 1px solid #fff;
        padding: 5px;
        color: #fff;

        text-align: right;

    }

    .topNav a{
        color: #eee;
    }
</style>