<template>
    <div class="main-box-shadow">
        <h3>ویرایش استان
        <v-btn color="red" @click="delprovince" dark x-small>حذف استان</v-btn>
        </h3>
        <v-text-field v-model="item.name" label="عنوان استان"></v-text-field>
      <v-row>
        <v-col cols="6"><v-text-field rounded filled label="شماره همراه" v-model="item.tell"></v-text-field></v-col>
        <v-col cols="6"><v-text-field rounded filled label="شماره ثابت" v-model="item.phone"></v-text-field></v-col>
      </v-row>

        <div>
            <mapir :zoom="zoom" @load="fly(item.lat,item.long)" class="map" ref="map" :apiKey="$store.state.mapApi"
                   @click="markerOnClick"
                   :center="coordinates">
                <mapAttributionControl/>
                <mapNavigationControl position="top-right"/>
                <mapGeolocateControl position="top-right"/>
                <mapMarker
                        :coordinates.sync="markerCoordinates"
                        color="none"
                />
            </mapir>
        </div>
        <v-btn class="btn-action" color="primary" @click="editProvince" block rounded>ویرایش</v-btn>
        <v-btn class="btn-action" to="/citylist" color="error" block rounded>لغو کردن</v-btn>
    </div>
</template>

<script>
    import axios from "axios";

    const mapNavigationControl = () => import( 'mapir-vue/src/components/UI/controls/NavigationControl');
    const mapGeolocateControl = () => import( 'mapir-vue/src/components/UI/controls/GeolocateControl');
    const mapAttributionControl = () => import( 'mapir-vue/src/components/UI/controls/AttributionControl');
    const mapMarker = () => import('mapir-vue/src/components/UI/Marker.vue');
    const mapir = () => import('mapir-vue/src/components/map/Map.vue');
    export default {
        name: "CreateProvince",

        created() {
            axios.get(this.$store.state.admin + 'getprovince', {params: {id: this.id}}).then(response => {
                if (response.data.result) {
                    this.item = response.data.data;
                }
            })
        },

        data() {
            return {
                item: {},
                id: this.$route.params.id,
                zoom: 11,
                coordinates: [52.35804277783245, 36.47193069037883],
                markerCoordinates: [52.35804277783245, 36.47193069037883],
            }
        },
        methods: {

            delprovince() {
                this.loading = true;
                axios.post(this.$store.state.admin + 'delprovince', {
                    id: this.id,
                }).then(response => {
                    if(response.data.result){
                        this.$router.push('/citylist')
                    }
                });
            },

            editProvince() {
                axios.post(this.$store.state.admin + 'editprovince', this.item).then(response => {
                    if (response.data.result) {
                        this.$router.push('/citylist');
                    }
                })
            },
            markerOnClick(event) {
                this.markerCoordinates = [event.actualEvent.lngLat.lng, event.actualEvent.lngLat.lat];
              this.item.lat=this.lat = event.actualEvent.lngLat.lat;
                this.item.long = this.long = event.actualEvent.lngLat.lng;

            },
            fly(lat, long) {
                this.lat = lat;
                this.long = long;
                this.$refs.map.actions.flyTo({
                    center: [long, lat],
                    speed: 10,
                    zoom: 10
                }).then(res => {
                    this.markerCoordinates = [res.center.lng, res.center.lat];
                    this.lat = lat;
                    this.long = long;
                });
            },
        },
        components: {
            mapir,
            mapMarker,
            mapAttributionControl,
            mapNavigationControl,
            mapGeolocateControl,
        }
    }
</script>

<style scoped>
    .map {
        border-radius: 20px;
        overflow: hidden;
        border: 10px #fff solid;
        box-shadow: 0px 0px 10px #eee;
        width: 100%;
        min-height: 300px;
    }

    .btn-action {
        margin: 10px auto;
    }
</style>