<template>
    <div>

        <div class="container-fluid sebMenu">

            <AddUser role="agent" role-title="نماینده"></AddUser>

            <transition name="slide">
                <div :key="1" class="filters" v-show="filter">
                    <div class="filterField">
                        <v-text-field readonly v-model="DateBegin" id="DateBegin" :prepend-icon="icon.mdiCalendar"
                                      label="از تاریخ"
                                      clearable/>
                        <date-picker format="jYYYY/jMM/jDD" element="DateBegin"  :max="(DateEnd?DateEnd:today)" v-model="DateBegin"
                                     :clearable="true"/>
                    </div>
                    <div class="filterField">
                        <v-text-field readonly v-model="DateEnd" id="DateEnd" :prepend-icon="icon.mdiCalendar"
                                      label="تا تاریخ"
                                      clearable/>
                        <date-picker format="jYYYY/jMM/jDD" element="DateEnd" :min="DateBegin" :max="today" v-model="DateEnd"
                                     :clearable="true"/>
                    </div>
                    <div class="filterField">
                        <v-text-field clearable v-model="Name" label="نام یا نام خانوادگی"></v-text-field>
                    </div>

                    <div class="filterField">
                    <v-text-field clearable v-model="tell" label="شماره"></v-text-field>
                </div>

                    <div class="filterField">
                        <v-btn color="blue" class="filterBtn" dark rounded :loading="loading" @click="sendFilter()"> اعمال فیلتر</v-btn>
                    </div>

                </div>
            </transition>


        </div>




        <div class="main small-box container">
            <h4 class="titles">نمایندگان


                {{count?'('+count+')':''}}

                <v-btn rounded v-if="!filter" color="green" dark @click="filter=!filter">
                    <v-icon>{{icon.mdiFilter}}</v-icon>
                    فیلتر
                </v-btn>
                <v-btn rounded v-else color="red" dark @click="filter=!filter">
                    x
                    بستن فیلتر ها
                </v-btn>
            </h4>
            <v-skeleton-loader v-if="loading" type="article@5">
            </v-skeleton-loader>
            <div v-else>
                <div v-if="items.length<1">
بدون نماینده                </div>
            </div>

            <div v-for="item in items" :key="item">
                <SupportUserItem :item="item"></SupportUserItem>
            </div>


            <div class="text-center">
                <v-pagination

                        @input="pagination"
                        v-model="page"
                        :length="Math.ceil(count/20)"
                        circle
                ></v-pagination>
            </div>


        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import SupportUserItem from "@/components/Users/SupportUserItem";
    import {mdiCalendar, mdiFilter} from '@mdi/js'
    import VuePersianDatetimePicker from 'vue-persian-datetime-picker'
    import moment from 'moment-jalaali'
    import AddUser from "@/components/Users/Profile/AddUser";


    export default {
        watch: {
            '$route'() {
                this.loading = true;
                this.page = this.$route.query.page;
                this.send();
            }
        },

        created() {
            if (!this.page) {
                this.page = 1;
            }

            this.today = moment.unix(Date.now() / 1000).locale('fa').format('jYYYY/jMM/jDD');
            this.send();
        },
        methods: {

            sendFilter(){
              this.page=1;
                this.$router.push({query: {page: this.page}})
                this.send();
            },

            send(){
                this.loading = true;
                axios.get(this.$store.state.admin + 'agents?page=' + this.page +
                    '&Name=' + (this.Name?this.Name:'') +
                    '&DateEnd=' + (this.DateEnd?this.DateEnd:'') +
                    '&DateBegin=' + (this.DateBegin?this.DateBegin:'') +
                    '&tell=' + (this.tell?this.tell:'')).then(response => {
                    this.items = response.data.data.items;
                    this.count = response.data.data.count;
                    this.loading = false;
                })
            },

            pagination() {
                this.$router.push({query: {page: this.page}})
            }
        },
        data() {
            return {

                filter: false,
                icon: {mdiCalendar, mdiFilter},
                today: null,
                status:'',
                allstatus:[{
                    text:'منتظر تایید',
                    value:'wait'
                },{
                    text:'تایید شده',
                    value:'confirm'
                },{
                    text:'درحال انجام',
                    value:'sendp'
                },{
                    text:'انجام شده',
                    value:'done'
                },{
                    text:'تمام شده',
                    value:'end'
                },{
                    text:'کنسل شده',
                    value:'cancel'
                },],
                Name: '',
                service: '',
                City: '',
                tell: '',
                DateBegin: '',
                DateEnd: '',
                loading: true,
                page: parseInt(this.$route.query.page),
                count: 0,
                userId: this.$store.state.userId,
                items: null,
            }
        }
        ,
        components: {
            AddUser,
            SupportUserItem,
            datePicker: VuePersianDatetimePicker
        }
    }
</script>

<style scoped>

    .allItems {
        margin: 20px auto;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }


    .filters {
        display: flex;
        flex-wrap: wrap;
        justify-content: right;
        width: 600px;
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    .filterField {
        width: 180px !important;
        margin-left: 20px;
    }

    .titles {
        text-align: right;
    }

    .main {
        margin-top: 20px;
    }

    .titles {
        margin-right: 20px;
    }
    .filterBtn{
        margin-top: 20px;
    }



</style>