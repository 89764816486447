<template>
    <div class="shadow centerBlock">


        <div class="editForm">


            <h3 class="titles">

                ارسال مدارک
                کاربر به شناسه
                <router-link :to="'/profiledetail/'+profile.userId">{{profile.userId}}</router-link>

                <v-btn v-if="profile.confirmed!=1" :loading="loading" rounded @click="confirm" color="success" >تایید</v-btn>
            </h3>






            <div class="panelItem">
                <h3 class="panelLabel" :style="{color:bgStatus.color}">وضعیت </h3>
                <div class="panelValue statusVal" :style="{backgroundColor:bgStatus.color}"> {{bgStatus.label}}</div>

            </div>
            <div class="desc" v-if="profile.desc" :style="{backgroundColor:bgStatus.color}">
                <small>
                    {{profile.desc}}
                </small>
            </div>

            <div class="Filleds">
                <v-text-field class="FilledsInput" :disabled="profile.confirmed==1" :loading="loading"
                              v-model="profile.fName"
                              label="نام"></v-text-field>
                <v-text-field class="FilledsInput" :disabled="profile.confirmed==1" :loading="loading"
                              v-model="profile.lName"
                              label="نام خانوادگی"></v-text-field>
                <v-text-field class="FilledsInput" :disabled="profile.confirmed==1" :loading="loading"
                              v-model="profile.tell"
                              label="شماره تماس"></v-text-field>

                <v-text-field class="FilledsInput" :disabled="profile.confirmed==1" :loading="loading"
                              v-model="profile.idCode"
                              label="شماره ملی"></v-text-field>

            </div>

            <v-autocomplete  :disabled="profile.confirmed==1" :items="cityList" label="شهر محل زندگی" item-value="id" item-text="name" v-model="profile.cityId"></v-autocomplete>

            <v-textarea :disabled="profile.confirmed==1" :loading="loading" filled v-model="profile.address"
                        label="آدرس"></v-textarea>

            <div class="images">

                <div>
                    <h5 class="titles" :class="{errorForm:!profile.image }">
                        <v-icon>{{mdiImage}}</v-icon>
                        عکس پرسنلی
                        <v-btn v-if="profile.image" target="_blank" icon color="info"
                               :href="$store.state.file+profile.image">
                            <v-icon>{{icon.mdiMagnify}}</v-icon>
                        </v-btn>


                    </h5>

                    <label>
                        <input :disabled="profile.confirmed==1||ImageUploadLoading" type="file" ref="fileUploadImage" @change="uploadImage"
                               class="upload">
                        <div class="imageLabel">

                            <v-card flat :class="{errorFormBorder:!profile.image }" :loading="loading">
                                <img class="image" :src="$store.state.thumbnail+profile.image">
                            </v-card>
                        </div>
                    </label>
                   <upload-progress :loading="ImageUploadLoading" :percentage="ImageUploadPercentage"></upload-progress>

                </div>


                <div>
                    <h5 class="titles" :class="{errorForm:!profile.idCard }">
                        <v-icon>{{mdiImage}}</v-icon>
                        تصویر کارت ملی
                        <v-btn v-if="profile.idCard" target="_blank" icon color="info"
                               :href="$store.state.file+profile.idCard">
                            <v-icon>{{icon.mdiMagnify}}</v-icon>
                        </v-btn>

                    </h5>
                    <label>
                        <input :disabled="profile.confirmed==1||IdCardUploadLoading" type="file" ref="fileUploadId" @change="uploadId"
                               class="upload">
                        <div class="imageLabel">

                            <v-card flat :class="{errorFormBorder:!profile.idCard }" :loading="loading">
                                <img class="image" :src="$store.state.thumbnail+profile.idCard">
                            </v-card>
                        </div>
                    </label>
                    <upload-progress :loading="IdCardUploadLoading" :percentage="IdCardUploadPercentage"></upload-progress>

                </div>
                <div>
                    <h5 class="titles" :class="{errorForm:!profile.cardFace }">
                        <v-icon>{{mdiImage}}</v-icon>
                        تصویر کارت ملی در دست شما
                        <v-btn v-if="profile.cardFace" target="_blank" icon color="info"
                               :href="$store.state.file+profile.cardFace">
                            <v-icon>{{icon.mdiMagnify}}</v-icon>
                        </v-btn>

                    </h5>
                    <label>
                        <input :disabled="profile.confirmed==1||FaceCardUploadLoading" type="file" ref="fileUploadCardFace"
                               @change="uploadcardFace" class="upload">
                        <div class="imageLabel">

                            <v-card flat :class="{errorFormBorder:!profile.cardFace }" :loading="loading">
                                <img class="image" :src="$store.state.thumbnail+profile.cardFace">
                            </v-card>
                        </div>
                    </label>
                    <upload-progress :loading="FaceCardUploadLoading" :percentage="FaceCardUploadPercentage"></upload-progress>

                </div>
                <div>
                    <h5 class="titles" :class="{errorForm:!profile.license }">
                        <v-icon>{{mdiImage}}</v-icon>
                        جواز فعالیت
                        <v-btn v-if="profile.license" target="_blank" icon color="info"
                               :href="$store.state.file+profile.license">
                            <v-icon>{{icon.mdiMagnify}}</v-icon>
                        </v-btn>
                    </h5>
                    <label>
                        <input :disabled="profile.confirmed==1||LicenseUploadLoading" type="file" ref="fileUploadlicense"
                               @change="uploadlicense"
                               class="upload">
                        <div class="imageLabel">

                            <v-card flat :class="{errorFormBorder:!profile.license }" :loading="loading">
                                <img class="image" :src="$store.state.thumbnail+profile.license">
                            </v-card>
                        </div>
                    </label>
                    <upload-progress :loading="LicenseUploadLoading" :percentage="LicenseUploadPercentage"></upload-progress>

                </div>
            </div>


            <h3 class="titles">تایید الکترونیکی قرارداد</h3>
            <p class="contract" style="text-align: justify" v-html="profile.contract">
            </p>


            <v-checkbox :disabled="profile.confirmed==1" color="success" :true-value="1" false-value="0" dense
                        v-model="profile.accept" label="متن قرارداد را کاملا خوانده ام و میپذریم"></v-checkbox>



            <v-text-field error filled label="توضیحات رد شدن" v-model="profile.desc"></v-text-field>
            <v-btn :loading="loading" rounded @click="notConfirm" color="red" dark >رد شدن</v-btn>



            <v-btn :loading="loading" @click="submit" :disabled="profile.confirmed==1" class="btn-kacheb">ثبت ویرایش
            </v-btn>
            <router-link to="/degreeList">
                <v-btn class="btn-kacheb">
                    برگشت
                </v-btn>
            </router-link>
        </div>


    </div>
</template>

<script>

    import axios from "axios";
    import {mdiMagnify} from '@mdi/js';
    import UploadProgress from "@/components/UploadProgress";

    export default {


        computed: {
            bgStatus() {
                if (this.profile.confirmed == 0 && this.profile.pending == 0) {
                    return {
                        color: '#ff1e28',
                        label: 'رد شده',
                    };
                }
                if (this.profile.confirmed == 1) {
                    return {
                        color: '#008c55',
                        label: 'تایید شده',
                    };

                }
                if (this.profile.confirmed == 0 && this.profile.pending == 1) {
                    return {
                        color: '#ff8905',
                        label: 'منتظر بررسی',
                    };
                }
                return {
                    color: '#525755',
                    label: 'ارسال نشده',
                }
            }
        },


        components: {UploadProgress},
        created() {
            axios.post(this.$store.state.api + 'getdegree?web=1', {
                userId: this.userId,
            }).then(response => {
                this.profile = response.data.data;
            });
            axios.post(this.$store.state.api + 'getcity', {
                id: 1,
            }).then(response => {
                this.cityList = response.data.data;
            });

        },
        data() {
            return {
                ImageUploadLoading:false,
                ImageUploadPercentage:0,
                IdCardUploadLoading:false,
                IdCardUploadPercentage:0,
                FaceCardUploadLoading:false,
                FaceCardUploadPercentage:0,
                LicenseUploadLoading:false,
                LicenseUploadPercentage:0,

                icon: {mdiMagnify},
                profile: {},
                userId: this.$route.params.id,
                loading: false,
                cityList:{}

            }
        },

        methods: {

            methods: {},


            uploadId: function () {
                this.IdCardUploadLoading = true;
                this.file = this.$refs.fileUploadId.files[0];
                let formData = new FormData();
                formData.append('file', this.file);
                axios.post(this.$store.state.api + 'imageupload?' +
                    'width=0&' +
                    'height=0&' +
                    'tw=300&' +
                    'th=300&' +
                    'stw=100&' +
                    'sth=100', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },onUploadProgress: function (progressEvent) {
                        this.IdCardUploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
                    }.bind(this)
                }).then(response => {
                        if (response.data.result) {
                            this.IdCardUploadLoading = false;
                            this.profile.idCard = response.data.data;
                        }
                    }
                ).catch(() => {
                    this.IdCardUploadLoading = false;
                    this.IdCardUploadPercentage = 0;
                    this.$store.state.notify.is = true;
                    this.$store.state.notify.text = 'خطای آپلود و پردازش تصویر';
                });
            },
            uploadcardFace: function () {
                this.FaceCardUploadLoading = true;
                this.file = this.$refs.fileUploadCardFace.files[0];
                let formData = new FormData();
                formData.append('file', this.file);
                axios.post(this.$store.state.api + 'imageupload?' +
                    'width=0&' +
                    'height=0&' +
                    'tw=300&' +
                    'th=300&' +
                    'stw=100&' +
                    'sth=100', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },onUploadProgress: function (progressEvent) {
                        this.FaceCardUploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
                    }.bind(this)

                }).then(response => {
                        if (response.data.result) {
                            this.FaceCardUploadLoading = false;
                            this.profile.cardFace = response.data.data;
                        }
                    }
                ).catch(() => {
                    this.FaceCardUploadLoading = false;
                    this.FaceCardUploadPercentage = 0;
                    this.$store.state.notify.is = true;
                    this.$store.state.notify.text = 'خطای آپلود و پردازش تصویر';
                });
            },
            uploadlicense: function () {
                this.LicenseUploadLoading = true;
                this.file = this.$refs.fileUploadlicense.files[0];
                let formData = new FormData();
                formData.append('file', this.file);
                axios.post(this.$store.state.api + 'imageupload?' +
                    'width=0&' +
                    'height=0&' +
                    'tw=300&' +
                    'th=300&' +
                    'stw=100&' +
                    'sth=100', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },onUploadProgress: function (progressEvent) {
                        this.LicenseUploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
                    }.bind(this)
                }).then(response => {
                        if (response.data.result) {
                            this.LicenseUploadLoading = false;
                            this.profile.license = response.data.data;
                        }
                    }
                ).catch(() => {
                    this.LicenseUploadLoading = false;
                    this.LicenseUploadPercentage = 0;
                    this.$store.state.notify.is = true;
                    this.$store.state.notify.text = 'خطای آپلود و پردازش تصویر';
                });
            },
            uploadImage: function () {
                this.ImageUploadLoading = true;
                this.file = this.$refs.fileUploadImage.files[0];
                let formData = new FormData();
                formData.append('file', this.file);
                axios.post(this.$store.state.api + 'imageupload?' +
                    'width=0&' +
                    'height=0&' +
                    'tw=300&' +
                    'th=300&' +
                    'stw=100&' +
                    'sth=100', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },onUploadProgress: function (progressEvent) {
                        this.ImageUploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
                    }.bind(this)
                }).then(response => {
                        if (response.data.result) {
                            this.ImageUploadLoading = false;
                            this.profile.image = response.data.data;
                        }
                    }
                ).catch(() => {
                    this.ImageUploadLoading = false;
                    this.ImageUploadPercentage = 0;
                    this.$store.state.notify.is = true;
                    this.$store.state.notify.text = 'خطای آپلود و پردازش تصویر';
                });
            },


            submit: function () {

                if(this.profile.fName&&this.profile.lName&&this.profile.image&&this.profile.accept>0&&this.profile.idCode&&this.profile.idCard&&this.profile.cardFace) {
                    this.loading = true;
                    axios.post(this.$store.state.api + 'setdegree', this.profile).then(response => {
                        this.profile = response.data.data;
                        this.loading = false;
                        this.$store.state.notify.text = 'ثبت موفق بود';
                        this.$store.state.notify.is = true;
                    });
                }else {
                    this.$store.state.notify.text = 'اطلاعات را درست وارد کنید';
                    this.$store.state.notify.is = true;
                }
            },
            confirm: function () {
                this.loading = true;
                axios.post(this.$store.state.admin + 'confirmdegree', {id:this.profile.id}).then(response => {
                    this.profile = response.data.data;
                    this.loading = false;
                    this.$store.state.notify.text = 'تایید موفق بود';
                    this.$store.state.notify.is = true;
                });
            }, notConfirm: function () {
                this.loading = true;
                axios.post(this.$store.state.admin + 'notconfirmdegree', {id:this.profile.id,desc:this.profile.desc}).then(response => {
                    this.profile = response.data.data;
                    this.loading = false;
                    this.$store.state.notify.text = 'رد موفق بود';
                    this.$store.state.notify.is = true;
                });
            },



        }
    }
</script>

<style scoped>

    .centerBlock {
        width: 600px;
        max-width: 90%;
        border-radius: 30px;
        background-color: #fff;
        margin: 50px auto;
    }


    .image {
        max-width: 100%;
        width: 250px;
        margin: 10px;
        box-shadow: 0px 0px 3px #ccc;
        transition: 0.5s;
        cursor: pointer;
    }

    .image:hover {
        opacity: 0.5;
        box-shadow: 0px 0px 8px #666;

    }

    .images {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }


    .desc {
        width: 95%;
        border-radius: 20px;
        padding: 10px;
        color: #fff;
        margin: auto auto;
    }


    .panelItem {
        display: flex;
        justify-content: space-between;
        width: 95%;
        background-color: #fff;
        border-radius: 30px;
        box-shadow: 0px 0px 3px #ccc;
        margin: 10px auto;

    }

    .panelLabel {
        margin: 10px 30px;
    }

    .panelValue {
        cursor: pointer;
        border-radius: 30px !important;
        padding: 10px 30px !important;
        transition: 0.5s;
        box-sizing: unset !important;
        font-size: 0.9em;
        display: inline-block;

    }

    .statusVal {
        color: #fff;
    }

    .contract {
        height: 300px;
        overflow: auto;
        border-radius: 0px 30px 30px 0px;
        background-color: #eee;
        border: 1px #ccc solid;
        padding: 20px;
        box-shadow: 0px 0px 10px #ccc inset;
    }


    .inputs {
        display: block;
        margin: 10px auto;
        text-align: center;


    }

    .shabaInput {
        width: 100%;
    }

    .main {
        padding-top: 150px;

    }

    .textHeader {
        display: block;
    }

    .textfooter {
        display: block;
        font-size: 12px;
    }

    .menuItem {
        cursor: pointer;
        text-align: right;
        margin-top: 20px;
        vertical-align: top;
    }

    .cameraIcon {
        position: absolute;
        bottom: 0px;
        right: 30%;
        width: 35px;
        cursor: pointer;
        opacity: 0.8;
        transition: 1s;
    }

    .cameraIcon:hover {
        opacity: 1;
    }

    .menuItems {
        padding: 20px;
    }

    .menutext {
        display: inline-block;
    }

    .menuIcon {
        height: 50px;
        margin: 0px 10px -10px 10px;
    }

    .editMenu {
        max-width: 600px;
        margin-bottom: 100px;
    }

    .tell {
        font-size: 14px;
        margin: 20px;
    }

    .profileImage {
        max-width: 70%;
        width: 300px;
        border-radius: 100%;
        border: 5px solid #f5f5f5;
        cursor: pointer;
        transition: 0.5s;
    }

    .profileImage:hover {
        opacity: 0.7;
    }

    .topBlock {
        width: 50%;
    }

    .imageLabel {
        position: relative;
    }

    .box-menu {
        background-color: #fff;
        border-radius: 30px;
        position: relative;
    }

    .moarefCode {
        position: absolute;
        left: 20px;
        top: -25px;
    }

    .profileTop {
        max-width: 100%;
        display: block;
    }

    .upload {
        display: none
    }


    @media screen and (max-width: 1200px) {
        .main {
            padding-top: 20px;

        }
    }

    @media screen and (max-width: 800px) {
        .main {
            padding-top: 20px;

        }

        .profileImage {
            max-width: 60%;
            border-radius: 100%;
        }

        .profileTop {
            max-width: 100%;
        }
    }


    @media screen and (max-width: 600px) {
        .main {
            padding-top: 0;

        }

        .profileImage {
            max-width: 60%;
        }

        .profileTop {
            max-width: 100%;
        }
    }

    .editForm {
        padding: 20px;
    }

</style>