<template>
    <div class="factor">
        <h4>#
            {{item.id}}
            :
            {{item.title}}
        </h4>
        <h5 style="color: #999">
            {{item.fullName}}
        </h5>
        <small>{{item.createTime|momentDate}}</small>
        <p>
            {{item.desc}}
        </p>
        <p>
              <span class="deCash" v-if="item.price>0">
                                  {{item.price|digit}}
تومان
              </span>


        </p>

        <div class="BtnBottom">

                <v-btn :loading="loading" small class="btn-action" @click="DeleteDialog=!DeleteDialog" rounded color="red" dark>حذف</v-btn>

                <v-dialog width="320" v-model="DeleteDialog">
                    <v-card class="confirmDialog">
                        <strong class="titles"> واقعا میخوای این هزینه رو حذف کنی؟</strong>
                        <div class="dialogTip">
                            <small>
                                اگر این هزینه رو حذف کنید مطمئنا دیگر به اطلاعات قبلی از قبیل متن و... اطلاعات دسترسی ندارید.
                            </small>
                        </div>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn class="confirmButton" small @click="DeleteDialog=false" dark color="red">خیر</v-btn>
                            <v-btn class="confirmButton" small @click="Delete" color="success">بله مطمئنم</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>


                    </v-card>
                </v-dialog>

        </div>

    </div>

</template>

<script>

    import moment from 'moment-jalaali'
    import axios from "axios";


    export default {


        filters: {
            momentDay: function (date) {
                return moment.unix(date).locale('fa').format('dddd');
            }, momentDate: function (date) {
                return moment.unix(date).locale('fa').format('jYYYY/jMM/jDD H:m');
            }
        },


        methods: {


            Delete() {
                this.loading = true;
                this.DeleteDialog = false;
                axios.post(this.$store.state.admin + 'delcost', {
                    id: this.item.id
                }).then(response => {
                    if (response.data.result) {
                        this.get();
                    }
                    this.loading = false;
                });
            }
            ,
            get() {
                this.$emit('get');
                this.loading = false;
            },
        },

            props: ['item'],
        data() {
            return {
                DeleteDialog:false,
                loading: false

            }
        },
    }
</script>

<style scoped>

    .inputs {
        display: block;
        margin: 10px auto;
        text-align: center;

    }


    .BtnBottom {
        position: absolute;
        left: 10px;
        bottom: -10px;
    }

    .factor {
        max-width: 600px;
        background-color: #fff;
        margin: 20px auto;
        padding: 20px !important;
        border-radius: 20px;
        text-align: right;
        box-shadow: 0px 0px 10px #eee;
        position: relative;

    }

    .deCash {
        color: #a00;

    }

    .addCash {
        color: #0a0;

    }

    .text {
        margin-top: 20px;
        text-align: right;
        margin-bottom: 20px;
    }

    .main {
        padding-top: 150px;
        display: flex;
        justify-content: center;
    }

    .textHeader {
        display: block;
    }

    .textfooter {
        display: block;
        font-size: 12px;
    }

    .menuItem {
        cursor: pointer;
        text-align: right;
        margin-top: 20px;
        vertical-align: top;
    }

    .cameraIcon {
        position: absolute;
        bottom: 0px;
        right: 30%;
        width: 35px;
        cursor: pointer;
        opacity: 0.8;
        transition: 1s;
    }

    .cameraIcon:hover {
        opacity: 1;
    }

    .menuItems {
        padding: 20px;
    }

    .menutext {
        display: inline-block;
    }

    .menuIcon {
        height: 50px;
        margin: 0px 10px -10px 10px;
    }

    .editMenu {
        max-width: 600px;
        margin-bottom: 100px;
    }

    .tell {
        font-size: 14px;
        margin: 20px;
    }

    .profileImage {
        max-width: 70%;
        width: 300px;
        border-radius: 100%;
        border: 5px solid #f5f5f5;
        cursor: pointer;
        transition: 0.5s;
    }

    .profileImage:hover {
        opacity: 0.7;
    }

    .topBlock {
        width: 50%;
    }

    .imageLabel {
        position: relative;
    }

    .box-menu {
        background-color: #fff;
        border-radius: 30px;
        position: relative;
    }

    .moarefCode {
        position: absolute;
        left: 20px;
        top: -25px;
    }

    .profileTop {
        max-width: 100%;
        display: block;
    }

    .upload {
        display: none
    }


    @media screen and (max-width: 1200px) {
        .main {
            padding-top: 20px;

        }
    }

    @media screen and (max-width: 800px) {
        .main {
            padding-top: 20px;

        }

        .profileImage {
            max-width: 60%;
            border-radius: 100%;
        }

        .profileTop {
            max-width: 100%;
        }
    }


    @media screen and (max-width: 600px) {
        .main {
            padding-top: 0;

        }

        .profileImage {
            max-width: 60%;
        }

        .profileTop {
            max-width: 100%;
        }
    }


</style>