<template>
<div :style="{backgroundColor:bgStatus(item)}" class="items shadow">
    <div class="all">
    <div class="menuItem">
        <img class="menuIcon" :src="$store.state.thumbnail+item.serviceIcon">
        <v-btn :to="'/orderdetail/'+item.id" @click="$store.state.refDialog=false" class="btn-kacheb btn-service">جزئیات</v-btn>

        <div class="menutext">
                        <span class="textHeader">
                        {{item.serviceTitle}}
                        </span>
            <div class="called" v-if="item.tellOrder>0">
                <v-icon color="red" small>{{icons.mdiPhone}}</v-icon>
            </div>
            <div v-else class="called">
                <v-icon color="green" small>{{icons.mdiCellphoneAndroid}}</v-icon>
            </div>


            <span class="textfooter">
                {{item.CustomerName}} - <b>{{item.cityName}}</b>
                <br>{{item.tell}}
                <br v-if="item.providers.length>0">

                <v-chip  style="margin: 2px" color="success" small v-for="(provider,index) in item.providers" :key="index">
                    <router-link  style="color: #fff" :to="'/profiledetail/'+provider.providerId">
                       {{provider.fName}} {{provider.lName}}
                <template v-if="provider.price>0">
                    :
                     <span>  {{provider.price|digit}}</span>$
                </template>
                    </router-link>


            </v-chip>
                <br>
                <v-chip  style="margin: 2px" dark color="red" small>

                       {{item.refTime|momentDate}}
                   ( {{$fn.timeFa(item.refTime)}})
            </v-chip>
                        </span>

        </div>

    </div>

    <div >
        <span class="status" :style="{backgroundColor:bgStatus2(item)}"  >
           {{item.status}}
        </span>

    </div>
    <div class="orderDate">{{item.createTime | momentDay}}<br>{{item.createTime | momentDate}}</div>


    </div>



</div>
</template>

<script>
    import moment from "moment-jalaali";
    import {mdiPhone,mdiCellphoneAndroid} from "@mdi/js"

    export default {
        filters:{
            momentDate(date){
                return moment.unix(date).locale('fa').format('jYYYY/jMM/jDD HH:mm');
            },
            momentDay(date){
                return moment.unix(date).locale('fa').format('dddd');
            },
        },
        props:['item'],
        data(){
            return{
                icons:{mdiPhone,mdiCellphoneAndroid}
            }
        },
        methods: {



            bgStatus(item) {
                if (item.cancelTime > 0) {
                    return '#ffc0c0';
                }
                if (item.customerConfirm > 0) {
                    return '#eee';
                }
                if (item.DoneTime > 0) {
                    return '#c0e4ff';
                }
                if (item.sendProviderTime > 0) {
                    return '#9f9';
                }
                if (item.confirmTime > 0) {
                    return '#c0ffc7';
                }
                return '#fbffc0';
            },
            bgStatus2(item) {
                if (item.cancelTime > 0) {
                    return '#a22';
                }
                if (item.customerConfirm > 0) {
                    return '#aaa';
                }
                if (item.DoneTime > 0) {
                    return '#48f';
                }
                if (item.sendProviderTime > 0) {
                    return '#050';
                }
                if (item.confirmTime > 0) {
                    return '#2a2';
                }
                return '#e80';
            },
        }

    }
</script>

<style scoped>

    .menuItem {
        display: flex;
        justify-content: right;
        flex-wrap: wrap;
        text-align: right;
        width: 75%;
    }
    .all{
        display: flex;
        justify-content: space-between;
        text-align: right;
        vertical-align: middle;
    }
    .menuIcon {
        height: 50px;
        margin: -5px 10px 10px 10px;
        background-color: #fff;
        border-radius: 50%;
        padding: 3px;
    }

   .items{
       position: relative;
       background-color: #fff;
        border-radius: 20px;
        padding: 20px;
        margin: 20px 15px;
       color: #444;
    }

    .btn-service{
        position: absolute;
        bottom: -20px;
        left: 20px;
    }

    .menutext {
        display: inline-block;
    }

    .textfooter {
        display: block;
        font-size: 12px;
    }
    .status{
        display: inline-block;
        border-radius: 30px;
        padding: 5px;
        font-size: 12px;
        color: #fff;
        box-shadow: 0px 0px 10px #ccc;
        text-align: center;
    }
    .orderDate{
        color: #222;
        font-size: 13px;
        text-align: center;
    }

    .called{
        position: absolute;
        left: 5px;
        top: 0;
        padding-left: 10px;
    }

</style>