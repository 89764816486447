// src/plugins/vuetify.js

import Vue from 'vue'
import Vuetify from 'vuetify/lib'
Vue.use(Vuetify);
const opts = {
    icons: {
        iconfont: 'mdiSvg',
    },
    rtl: true,
};
export default new Vuetify(opts)