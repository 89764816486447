<template>
    <div>
        <div class="main small-box container" v-if="item">
            <h4 class="titles">
                ویرایش
                :
                {{item.title}}
            </h4>

<v-checkbox v-if="item.enable!=null" :true-value="1" :false-value="0" v-model="item.enable" label="فعال"></v-checkbox>
            <template v-if="item.type==='text'">
                <v-textarea :error="!item.value" outlined rows="12" :label="item.title" v-model="item.value"></v-textarea>
            </template>
            <template v-if="item.type==='input'">
                <v-text-field :error="!item.value" outlined  :label="item.title" v-model="item.value"></v-text-field>
            </template>
            <template v-else-if="item.type==='image'">

                <div>
                    <label>
                        <input type="file" ref="file" @change="upload" class="upload">
                        <div class="imageLabel">
                            <h5 class="titles" :class="{errorForm:!item.value }">
                                <v-icon>{{icon.mdiImage}}</v-icon>
                                تصویر را انتخاب کنید
                            </h5>
                            <v-card  :loading="loading" style="width: 155px">
                                <img class="imageIcon" :src="$store.state.thumbnail+item.value">
                            </v-card>
                        </div>
                    </label>
                </div>


            </template>
            <template v-else-if="item.type==='html'">

                <h3 class="titles">متن کامل {{item.title}}</h3>
                <div :class="{errorFormBorder:!item.value }">
                    <Editor :disabled="uploadArticleLoading" v-model="item.value"
                            api-key="x3joo5gaaegpn022fg37xcodmtt65vowxxxwcsilhgw0aue5"
                            :init="{selector: 'textarea',
                language: 'fa',
                height: 500,
                menubar:true ,
                plugins: [
           'advlist autolink lists link image charmap print preview anchor',
           'searchreplace visualblocks code fullscreen',
           'insertdatetime media table paste code help wordcount'
         ],
                toolbar:
           'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
       }"
                    />
                </div>
                <upload-progress :loading="uploadArticleLoading" :percentage="uploadArticlePercentage"
                                 v-if="uploadArticleLoading"></upload-progress>
                <label v-else>
                    <input type="file" ref="fileArticle" @change="uploadArticle" class="upload">
                    <div class="imageLabel">
                        <v-card flat background="transparent" :loading="loading">


                            <template>
                                <v-icon>{{icon.mdiImage}}</v-icon>
                                افزودن تصویر به مقاله
                            </template>

                        </v-card>
                    </div>
                </label>

            </template>

            <v-btn :loading="loading" @click="submit" class="btn-kacheb">ثبت ویرایش</v-btn>
            <v-btn :loading="loading" to="/configs" color="red" class="btn-kacheb">برگشت</v-btn>

        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import {mdiImage} from '@mdi/js'

    import Editor from '@tinymce/tinymce-vue'
    import UploadProgress from "@/components/UploadProgress";


    export default {
        created() {
            axios.post(this.$store.state.admin + 'getconfig',{id:this.id}).then(response => {
                this.item = response.data.data;
            });
        },
        data() {
            return {
                uploadArticlePercentage: 0,
                uploadArticleLoading: false,
                icon: {mdiImage},
                userId: this.$store.state.userId,
                item: null,
                id: this.$route.params.id,
                loading: false
            }
        }
        ,
        components: {
            UploadProgress,
            Editor
        },

        methods: {

            upload: function () {
                this.loading = true;
                this.file = this.$refs.file.files[0];
                let formData = new FormData();
                formData.append('file', this.file);
                axios.post(this.$store.state.api + 'imageupload?' +
                    'width=&' +
                    'height=&' +
                    'tw=400&' +
                    'th=400&' +
                    'stw=200&' +
                    'sth=200&'
                    , formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(response => {
                        if (response.data.result) {
                            this.loading = false;
                            this.item.value = response.data.data;
                        }
                    }
                );
            },



            submit() {

                if (this.item.value) {
                    this.loading = true;
                    axios.post(this.$store.state.admin + 'setconfig', this.item).then(response => {
                        if (response.data.result) {
                            this.$store.state.notify.is = true;
                            this.$store.state.notify.text = 'تنظیمات با موفقیت ویرایش شد';
                            this.loading = false;
                            this.$router.push('/configs');

                        } else {
                            this.$store.state.notify.is = true;
                            this.$store.state.notify.text = 'ویرایش ناموفق بود';
                            this.loading = false;
                        }

                    });
                } else {
                    this.$store.state.notify.is = true;
                    this.$store.state.notify.text = 'همه داده ها را وارد کنید';
                }
            },
            uploadArticle: function () {
                this.uploadArticleLoading = true;
                this.uploadArticlePercentage = 0;
                this.file = this.$refs.fileArticle.files[0];
                let formData = new FormData();
                formData.append('file', this.file);
                axios.post(this.$store.state.api + 'imageupload?' +
                    'width=800&' +
                    'height=600&' +
                    'tw=640&' +
                    'th=480&' +
                    'stw=300&' +
                    'sth=200&', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: function (progressEvent) {
                        this.uploadArticlePercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
                    }.bind(this)
                }).then(response => {
                        if (response.data.result) {


                            let html = '<p><img class="v-responsive v-image" alt="تصاویر کچب" style="width: 100%" src="' + this.$store.state.file + response.data.data + '" /></p>';
                            /*eslint-disable no-undef*/
                            tinymce.activeEditor.execCommand('mceInsertContent', false, html);
                            /*eslint-enable no-undef*/
                            this.uploadArticleLoading = false;

                        }
                    }
                ).catch(() => {
                    this.uploadArticleLoading = false;
                    this.uploadArticleLoading = 0;
                    this.$store.state.notify.is = true;
                    this.$store.state.notify.text = 'خطای آپلود و پردازش تصویر';
                });
            },
        }
    }
</script>

<style scoped>

    .allItems {
        margin: 20px auto;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }


    .upload {
        display: none;
    }

    .titles {
        text-align: right;
    }

    .errorForm {
        color: red;
    }

    .imageIcon {
        max-width: 90%;
        border-radius: 5px;
        margin-bottom: 10px;
    }

    .errorFormBorder {
        border: 2px solid #f55 !important;
    }

    .main {
        margin-top: 20px;
    }

    .titles {
        margin-right: 20px;
    }

    .sebMenu {
        background-color: #f5f5f5;
        box-shadow: 0px 0px 3px #ccc;
    }

    .image {
        max-width: 100%;
        border-radius: 5px;
        margin-bottom: 10px;
    }

    .upload {
        display: none;
    }

    .imageLabel:hover {
        cursor: pointer;
        opacity: 0.8;
        transition: 0.3s;
    }

    .imageLabel {
        margin-bottom: 10px;
    }

</style>