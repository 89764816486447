<template>
    <div v-if="$store.state.userId">

    <v-navigation-drawer
            :absolute="true"
            v-model="drawer"
            right
            dark
            hide-overlay
            color="grey darken-4"
            class="Drawer"

    >

        <v-app-bar-nav-icon style="float: left" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

        <v-list nav dense>
            <router-link to="/">
                <img alt="بنر نوژا سرویس" class="DrawerLogo" src="../../src/assets/DrawerLogo.png">
            </router-link>
            <v-list-item-group
                    class="darwerText"
                    align="right"
                    active-class="activeLink"
            >

                <div class="menutitle">سفارشات</div>
                <v-list-item to="/search">
                    <v-icon  >{{icon.mdiFolderPlusOutline}}</v-icon>
                    <v-list-item-title>
                        سفارش جدید
                    </v-list-item-title>
                </v-list-item>
                <v-list-item to="/allorders">
                    <v-icon  >{{icon.mdiCheckAll}}</v-icon>
                    <v-list-item-title>
                        همه سفارشات
                    </v-list-item-title>
                </v-list-item>
                <v-list-item to="/ordercomments">
                    <v-icon  >{{icon.mdiComment}}</v-icon>
                    <v-list-item-title>
                        نظرات خدمات
                    </v-list-item-title>
                </v-list-item>



                <div class="menutitle" v-if="Admin">بلاگر</div>
                <v-list-item to="/addpost" v-if="Admin">
                    <v-icon  >{{icon.mdiFolderPlusOutline}}</v-icon>
                    <v-list-item-title>
                        پست جدید
                    </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="Admin" to="/allposts">
                    <v-icon  >{{icon.mdiCheckAll}}</v-icon>
                    <v-list-item-title>
                        همه پستها
                    </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="Admin" to="/postcomments">
                    <v-icon  >{{icon.mdiComment}}</v-icon>
                    <v-list-item-title>
                        نظرات پستها
                    </v-list-item-title>
                </v-list-item>


                <div v-if="Admin" class="menutitle">خدمات</div>
                <v-list-item v-if="Admin" to="/services">
                    <v-icon  >{{icon.mdiFolderPlusOutline}}</v-icon>
                    <v-list-item-title>
                        خدمات
                    </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="Admin" to="/cats">
                    <v-icon  >{{icon.mdiCheckAll}}</v-icon>
                    <v-list-item-title>
                        دسته بندی ها
                    </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="Admin" to="/statistic">
                    <v-icon  >{{icon.mdiComment}}</v-icon>
                    <v-list-item-title>
                        آمار
                    </v-list-item-title>
                </v-list-item>


                <div class="menutitle">کاربران</div>
                <v-list-item to="/customers">
                    <v-icon  >{{icon.mdiAccount }}</v-icon>
                    <v-list-item-title>
                        مشتریان
                    </v-list-item-title>
                </v-list-item>
                <v-list-item to="/provider">
                    <v-icon  >{{icon.mdiAccount}}</v-icon>
                    <v-list-item-title>
                       استادکاران
                    </v-list-item-title>
                </v-list-item>
                <v-list-item to="/tellcustomers">
                    <v-icon  >{{icon.mdiAccount}}</v-icon>
                    <v-list-item-title>
                        مشتریان تلفنی
                    </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="Admin" to="/bloggers">
                    <v-icon  >{{icon.mdiAccount}}</v-icon>
                    <v-list-item-title>
                       بلاگرها
                    </v-list-item-title>
                </v-list-item>
                <v-list-item to="/supporter" v-if="Admin">
                    <v-icon  >{{icon.mdiAccount}}</v-icon>
                    <v-list-item-title>
                       پشتیبانها
                    </v-list-item-title>
                </v-list-item>
                <v-list-item to="/admins" v-if="Admin">
                    <v-icon  >{{icon.mdiAccount}}</v-icon>
                    <v-list-item-title>
                        مدیران
                    </v-list-item-title>
                </v-list-item>




            </v-list-item-group>
        </v-list>
    </v-navigation-drawer>


        <template>
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        </template>
    </div>

</template>

<script>


    import { VIcon,VListItemTitle,VNavigationDrawer } from 'vuetify/lib'
    import { mdiFolderPlusOutline,mdiCheckAll ,mdiComment,mdiMenuLeft ,mdiAccount    } from '@mdi/js'

    export default {

        data() {
            return {
                drawer: false,
                icon: {mdiFolderPlusOutline,mdiCheckAll ,mdiComment,mdiMenuLeft,mdiAccount }
            }
        },
        methods:{

        },
        components:{
            VIcon,VListItemTitle,VNavigationDrawer
        },
        computed: {
            /**
             * @return {boolean}
             */
            Admin() {
                return this.$store.state.adminRole;
            },
            loggedIn() {
                return this.$store.state.userId;
            },
            Width(){
                return this.$store.state.Width;
            },
            indexHeader: function () {
                if (this.$route.name === 'Index') {
                    return {
                        boxShadow: 'unset!important',
                        border: 'none'
                    };
                } else {
                    return {}
                }
            },

        },
    }
</script>

<style scoped>

    @media screen and (max-width: 1000px) {
        .menuText {
            margin-top: 10px;
            font-size: 0.8em;
            display: block;
        }

    }

    .singleMenu{
        margin-top: auto;
        margin-bottom: auto;
    }
    @media screen and (max-width: 800px) {
        .menuText {
            margin-top: 7px;
            font-size: 0.8em;
            display: block;
        }

        .logo {
            height: 60px !important;
        }
        .maim-menu{
            height: 60px!important;
        }
    }


    @media screen and (max-width: 600px) {

        .menuText {
            margin-top: 5px;

            font-size: 0.8em;

        }

    }


    .menuText {
        vertical-align: top;
        top: 5px;
    }

    .maim-menu {
        position: relative;
        margin-right: auto;
        margin-left: auto;
        max-width: 1000px;
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        z-index: 10;
        height: 100px;
        margin-top: 0px;
    }

    .maim-menu a {
        color: #444;
    }

    .logo {
        height: 100px;
    }

    .Drawer{
        z-index: 10000000;
    }

    .v-list-item__title{
        font-size: 1em!important;
        margin: 20px;
    }


    .activeLink{
        background-color: #08130a;
        color: #ffffff;
    }
    .DrawerLogo{
        width: 70%;
    }


    .menutitle{
        background-color: #228a62;
        color: #fff;
        padding: 10px 20px;
        border-bottom: 1px #ccc solid;
        border-radius: 10px 10px 0px 0px;
        margin: 10px auto;

    }

    .topNav{
        width: 100%;
        background-color: #4c4d4b;
        border-bottom: 1px solid #fff;
        padding: 5px;
        color: #fff;

        text-align: right;

    }

    .topNav a{
        color: #eee;
    }


</style>