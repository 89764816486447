<template>
    <div class="shadow providerlistitem">
        <h3>نتیجه پیگیری و زمان پیگیری بعدی
        </h3>
        <small>موعد پیگیری بعدی: {{$fn.timeFa(refTime)}}</small>

        <v-textarea filled rounded v-model="log" label="گزارش"></v-textarea>
        <div class="fields">
            <v-text-field filled type="number" label="چند" v-model="count"></v-text-field>
            <v-select filled :items="SelectDay" label="دقیقه،ساعت،روز" item-value="value" item-text="label"
                      type="number" v-model="time"></v-select>
        </div>
        <v-btn @click="OrderNextRef" dark rounded block>ثبت پیگیری</v-btn>

    </div>

</template>

<script>
    import moment from "moment-jalaali";
    import {mdiCloseCircleOutline} from "@mdi/js"
    import axios from "axios";
    import AWN from "awesome-notifications";

    export default {
        filters: {
            momentDateTime(date) {
                return moment.unix(date).locale('fa').format('jYYYY/jMM/jDD HH:mm');
            },
            momentDate(date) {
                return moment.unix(date).locale('fa').format('jYYYY/jMM/jDD');
            },
            momentDay(date) {
                return moment.unix(date).locale('fa').format('dddd');
            },
        },
        props: ['refTime', 'id'],
        data() {
            return {
                SelectDay: [
                    {label: 'دقیقه', value: 60},
                    {label: 'ساعت', value: 3600},
                    {label: 'روز', value: 3600 * 24},
                ],
                notify: new AWN(this.$store.state.NotifyOption),
                log: '',
                count: '',
                time: '',
                DeleteProviderDialog: false,
                loading: false,
                icon: {mdiCloseCircleOutline}
            }
        },
        methods: {
            OrderNextRef() {
                if(!(this.log && this.count && this.time)) {
                    this.notify.alert('مقادیر را درست وارد کنید');
                    return;
                }
                this.loading = true;
                axios.post(this.$store.state.admin + 'ordernextref' + '?apiId=' + this.$store.state.userId, {
                    log: this.log,
                    id: this.id,
                    count: this.count,
                    time: this.time,
                }).then(response => {
                    if (response.data.result) {
                        this.log = this.count = this.time='';
                        this.notify.success('با موفقیت پیگیری ثبت شد');

                        this.get();
                    }
                });
            },

            get() {
                this.$emit('get');
                this.loading = false;
            },

        },

    }
</script>

<style scoped>

    .providerlistitem {
        background-color: #fff;
        padding: 20px;
        border-radius: 30px;
    }


    .fields {
        display: flex;
    }

</style>