<template>

    <div>
        <div class="main container">

            <h3 class="titles">همه ی تنظیمات</h3>

            <small>برای مرتب سازی بکشید</small>
            <br>
            <div style="background:orange"  class="sq"></div>
            سایت
            <div style="background:cyan" class="sq"></div>
            اپلیکیشن
            <div  style="background:red" class="sq"></div>
            بلاگر
            <div style="background:green" class="sq"></div>
            عمومی
            <v-skeleton-loader class="allItems" v-if="!items" type="avatar@25">
            </v-skeleton-loader>


            <div v-if="items">
                <draggable @change="saveDrag()" v-model="items" group="people" @start="drag=true" @end="drag=false">
                    <transition-group class="allItems">
                        <v-card dark :color="bgColor(item.part)" :to="{ name: 'Config', params: { id: item.id}}" v-for="(item,index) in items" :key="index" class="items" >
                            <v-icon x-small>{{icon.mdiPencil}}</v-icon>
                            <small>
                                   {{item.title}}
                               </small>
                        </v-card>
                    </transition-group>

                </draggable>


            </div>

        </div>
    </div>

</template>

<script>
    import axios from 'axios';
    import {VSkeletonLoader} from 'vuetify/lib'
    import draggable from 'vuedraggable'
    import {mdiPencil} from '@mdi/js'

    export default {
        created() {
            axios.get(this.$store.state.admin + 'allconfig').then(response => {
                this.items = response.data.data;
            })
        },
        components: {
            VSkeletonLoader,
            draggable
        }, metaInfo: {
            title: 'دسته بندی ها',
        },
        data() {
            return {
                draggable: true,
                items: false,
                result: [],
                icon:{mdiPencil}
            }
        },

        methods: {

            bgColor(part){
                if(part=='Site'){
                    return 'orange';
                }else if(part=='Mobile') {
                    return 'info'
                }else if(part=='blogger') {
                    return 'red'
                }else{
                    return 'success'
                }

            },


            saveDrag() {
                this.result = this.items.map(function (a) {
                    return a.id;
                });
                axios.post(this.$store.state.admin + 'sortconfigsave', {item: this.result}).then(() => {
                })
            }

        }

    }
</script>

<style scoped>



    .sq{
        width: 7px;
        height: 7px;
        display: inline-block;
        margin-right: 20px;

        background-color: #ffab40;
    }
    .allItems {
        margin: 0px auto;
        width: 600px;
        max-width: 100%;
        display: flex;
        justify-content: right;
        flex-wrap: wrap;
    }

    .main {
        margin-top: 50px;
    }

    .items {

        border-radius: 10px;
        padding: 5px 10px;
        margin: 15px 4px;
        min-height: 20px;
    }

    .titles {
        text-align: center;
    }

    .img {
        width: 100%;
        border-radius: 10px;
    }

    .dragable {
        border: dashed 1px #ccc;
    }

</style>