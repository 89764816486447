export class fn {
    static timeFa(time) {
        let date = new Date();
       let now= Math.round(date.getTime()/1000);

        let dif = time - now;
        if (dif > 0) {
            if (dif < 60) {
                return dif + ' ثانیه';
            }
            if (dif < 60 * 60) {
                return Math.round(dif / 60) + ' دقیقه آینده';
            }
            if (dif < 60 * 60 * 24) {
                return Math.round(dif / (60 * 60)) + ' ساعت آینده';
            }
            if (dif < 60 * 60 * 24 * 7) {
                return Math.round(dif / (60 * 60 * 24)) + '  روز آینده';
            }
            return Math.round(dif / (60 * 60 * 24 * 7)) + ' هفته آینده';
        } else {
            dif = now - time;
            if (dif > 0) {
                if (dif < 60) {
                    return dif + ' ثانیه پیش';
                }
                if (dif < 60 * 60) {
                    return Math.round(dif / 60) + ' دقیقه پیش';
                }
                if (dif < 60 * 60 * 24) {
                    return Math.round(dif / (60 * 60)) + ' ساعت پیش';
                }
                if (dif < 60 * 60 * 24 * 7) {
                    return Math.round(dif / (60 * 60 * 24)) + ' روز پیش';
                }
                return Math.round(dif / (60 * 60 * 24 * 7)) + ' هفته پیش';
            }
        }
    }





}




