<template>
    <div class="items shadow UserItem">
        <div class="all">
            <div class="menuItem">
                <router-link :to="'profiledetail/'+item.id">
                    <img class="menuIcon" :src="(item.image?($store.state.thumbnail+item.image):('/man.png'))">
                </router-link>
                <v-btn :to="'profiledetail/'+item.id" rounded color="success" class="detail">جزئیات</v-btn>
                <v-btn :to="'/allorders/?supportId='+item.userId" rounded color="primary" class="orders">سفارشات</v-btn>
                <v-btn :to="'/factors/0/'+item.userId" rounded color="brown" dark class="factors">فاکتور ها</v-btn>
                <v-btn v-if="!deleted" @click="delRole" small :loading="loading" rounded color="red" dark class="del">حذف
                </v-btn>
                <v-btn v-else @click="delRole" small :loading="loading" rounded color="green" dark class="del">برگشت</v-btn>

                <div class="menutext">
                    <router-link :to="'profiledetail/'+item.id">
                        <span class="textHeader">
                        {{item.fName}} {{item.lName}}<img class="confirm" v-if="item.degreeConfirm>0"
                                                          src="../../assets/confirm.png"/>
                        </span>
                    </router-link>
                    <span class="textfooter">
                {{item.tell}}
                        </span>
                </div>

            </div>
            <div class="orderDate">{{item.created_at|momentDate }}
                <br>
                موجودی:
                <span class="cash">
           {{item.cash|digit}}
       </span><br>
                امتیاز:
                <span class="rank">
           {{item.rank|digit}}
       </span>
            </div>

        </div>
        <div v-if="item.desc"
             style="text-align: right;margin-right: 55px;margin-bottom: 20px;font-size: 0.8em;color: #555">
            {{item.desc}}
        </div>

        <div style="text-align: right;margin-right: 50px;margin-bottom: 20px">

            <!--            <template v-if="item.city">-->
            <!--                <v-chip style="margin: 2px;" color="info" small v-for="(city,index) in item.city" :key="index+300">-->
            <!--                    {{city.name}}-->
            <!--                </v-chip>-->
            <!--            </template>-->
            <!--            <template v-if="item.service">-->
            <!--                <br>-->
            <!--                <v-chip style="margin: 2px" color="success" small v-for="(service,index) in item.service"-->
            <!--                        :key="index+200">-->
            <!--                    {{service.title}}-->
            <!--                </v-chip>-->
            <!--            </template>-->
            <!--            <template v-if="item.app">-->
            <!--                <br>-->
            <!--                <v-chip style="margin: 2px" dark :color="app.app==='customer'?'warning':'red'" small-->
            <!--                        v-for="(app,index) in item.app" :key="index+100">-->
            <!--                    <v-icon small>-->
            <!--                        {{icons.mdiCellphoneAndroid }}-->
            <!--                    </v-icon>-->
            <!--                    {{app.app==='customer'?'اپ مشتری':'اپ متخصص'}}-->
            <!--                </v-chip>-->
            <!--            </template>-->

        </div>


    </div>
</template>

<script>
    import moment from "moment-jalaali";
    import {mdiCellphoneAndroid} from '@mdi/js'
    import axios from "axios";
    import AWN from "awesome-notifications";

    export default {

        data() {
            return {
                notify: new AWN(this.$store.state.NotifyOption),
                deleted: false,
                loading: false,
                icons: {mdiCellphoneAndroid}
            }
        },

        filters: {
            momentDate(date) {
                return moment.unix(date).locale('fa').format('jYYYY/jMM/jDD');
            },
            momentDay(date) {
                return moment.unix(date).locale('fa').format('dddd');
            },
        },
        props: ['item'],
        methods: {
            delRole() {
                this.loading = true;
                axios.post(this.$store.state.admin + 'changerole', {
                    userId: this.item.userId,
                    role: 'bAdmin'
                }).then(response => {
                    if (response.data.result) {
                        if(this.deleted){
                            this.notify.success('کاربر دوباره پشتیبان شد');
                        }else {
                            this.notify.info('کاربر حذف شد');

                        }

                        this.deleted = !this.deleted;
                    }
                    this.loading = false;
                })
            },
        },
    }
</script>

<style scoped>

    .menuItem {
        display: flex;
        justify-content: right;
        text-align: right;
        width: 300px;
    }

    .all {
        display: flex;
        justify-content: space-between;
        text-align: right;
        vertical-align: middle;
    }

    .menuIcon {
        height: 50px;
        margin: -5px 10px 10px 10px;
    }

    .items {
        position: relative;
        background-color: #fff;
        border-radius: 20px;
        padding: 20px;
        margin: 20px 15px;
        color: #444;
        transition: 0.2s;
    }

    .items:hover {
        background-color: #f9f9f9;

    }

    .btn-service {
        position: absolute;
        bottom: -20px;
        left: 20px;
    }

    .orders {
        position: absolute;
        bottom: -10px;
        left: 100px;
    }

    .factors {
        position: absolute;
        bottom: -10px;
        left: 195px;
    }

    .del {
        position: absolute;
        bottom: -10px;
        right: 20px;
    }

    .detail {
        position: absolute;
        bottom: -10px;
        left: 20px;
    }

    .menutext {
        display: inline-block;
    }

    .textfooter {
        display: block;
        font-size: 12px;
    }

    .status {
        display: inline-block;
        border-radius: 30px;
        padding: 5px;
        font-size: 12px;
        color: #222;
        height: 30px;
        border: #eee 1px solid;
    }

    .orderDate {
        color: #222;
        font-size: 13px;
    }

    .UserItem {
        transition: 0.2s;
    }

    .UserItem:hover {
        box-shadow: 0px 0px 10px #ccc;
    }

</style>