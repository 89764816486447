<template>
    <v-progress-linear v-if="loading"
                       striped
                       rounded
                       height="20"
                       :value="percentage"
                       :color="percentage>99?'success':'warning'"
    >

        <template v-if="percentage>99">
            آپلود شد درحال پردازش
        </template>
        <template v-else>
            {{percentage}}%
        </template>

    </v-progress-linear>
</template>
<script>
    export default {
        data: () => ({}),
        props:['loading','percentage'],
    }
</script>