<template>
    <v-card flat class="small-box-city">
        <v-row>

            <v-col cols="12" sm="6">
                <v-text-field v-model="str" @change="send" hint="بعد از وارد کردن اسم شهر دکمه Enter رو بزنید" dense
                              filled rounded label="عنوان شهر"></v-text-field>
                <v-btn rounded color="success" block to="/createcity">+
                    افزودن شهر
                </v-btn>
            </v-col>
            <v-col cols="12" sm="6">

                <v-autocomplete @change="send" v-model="proId" :items="proList" item-text="name" item-value="id" dense
                                filled rounded
                                label="انتخاب استان">

                    <template v-if="proId" v-slot:append>
                        <v-btn color="orange" dark rounded :to="{name:'EditProvince',params:{id:proId}}" x-small>ویرایش</v-btn>
                    </template>

                </v-autocomplete>
                <v-btn rounded color="warning" block to="/createprovince">+
                    افزودن استان
                </v-btn>
            </v-col>
        </v-row>

        <h3 style="text-align: right;margin-bottom: 20px">لیست شهر ها

        </h3>
        <div class="flex-div">

            <template v-if="loading">
                <v-skeleton-loader class="btn-city" type="button" v-for="i in 40" :key="i+'1sk'">
                </v-skeleton-loader>
            </template>

            <v-btn :to="{name:'CityService',params:{id:item.id}}" v-else rounded class="btn-city" small color="primary"
                   v-for="(item,i) in items" :key="i">
                {{item.name}}
            </v-btn>
        </div>


    </v-card>
</template>

<script>
    import axios from "axios";

    export default {
        name: "CityList",
        created() {
            this.send();
            axios.get(this.$store.state.api + 'getprovince').then(response => {
                this.proList = response.data.data;
            });

        },
        data() {
            return {
                loading: false,
                items: null,
                proList: [],
                count: 0,
                proId: null,
                str: '',
            }
        },
        methods: {
            send() {
                this.loading = true;
                this.ServerRequest().then(response => {
                    this.items = response.data.data.data;
                    this.count = response.data.data.count;
                    this.loading = false;
                });
            },

            ServerRequest() {
                return axios.get(this.$store.state.admin + 'citylist', {
                    params: {
                        provinceId: this.proId,
                        str: this.str
                    }
                });
            },
        }
    }
</script>

<style scoped>
    .small-box-city {
        width: 600px;
        max-width: 100%;
        margin-right: auto;
        margin-left: auto;
        padding: 10px;

        box-shadow: 0px 5px 10px #ccc !important;
        margin-top: 0px;
        border-radius: 0px 0px 10px 10px;


    }

    .btn-city {
        margin: 2px;
    }

    .flex-div {
        justify-content: right;
        display: flex;
        flex-wrap: wrap;
    }
</style>