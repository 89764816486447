import { render, staticRenderFns } from "./CostItem.vue?vue&type=template&id=57710c64&scoped=true"
import script from "./CostItem.vue?vue&type=script&lang=js"
export * from "./CostItem.vue?vue&type=script&lang=js"
import style0 from "./CostItem.vue?vue&type=style&index=0&id=57710c64&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57710c64",
  null
  
)

export default component.exports