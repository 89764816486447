<template>
    <div>

        <div class="container-fluid sebMenu">
            <AddCost @get="send"></AddCost>

            <transition name="slide">
                <div :key="1" class="filters" v-show="filter">
                    <div class="filterField">
                        <v-text-field readonly v-model="DateBegin" id="DateBegin" :prepend-icon="icon.mdiCalendar"
                                      label="از تاریخ"
                                      clearable/>
                        <date-picker format="jYYYY/jMM/jDD" element="DateBegin" :max="(DateEnd?DateEnd:today)"
                                     v-model="DateBegin"
                                     :clearable="true"/>
                    </div>
                    <div class="filterField">
                        <v-text-field readonly v-model="DateEnd" id="DateEnd" :prepend-icon="icon.mdiCalendar"
                                      label="تا تاریخ"
                                      clearable/>
                        <date-picker format="jYYYY/jMM/jDD" element="DateEnd" :min="DateBegin" :max="today"
                                     v-model="DateEnd"
                                     :clearable="true"/>
                    </div>
                    <div class="filterField">
                        <v-text-field clearable v-model="search" label="جستجو"></v-text-field>
                    </div>
                    <div class="filterField">
                        <v-select clearable @click:clear="type=''" v-model="type" :items="alltype"
                                  item-text="text" item-value="value" label="نوع"></v-select>
                    </div>

                    <div class="filterField">
                        <v-btn color="blue" dark rounded :loading="loading" @click="sendFilter()"> اعمال فیلتر</v-btn>
                    </div>

                </div>
            </transition>


        </div>


        <div class="main small-box container">
            <h4 class="titles">هزینه ها


                {{count?'('+count+')':''}}

                از تاریخ

                <span class="cash">
                   {{DateBegin?DateBegin:'ابتدا'}}
               </span>
                تا
                <span class="cash"> {{DateEnd?DateEnd:'اکنون'}}
</span>

                <v-btn rounded v-if="!filter" color="green" dark @click="filter=!filter">
                    <v-icon>{{icon.mdiFilter}}</v-icon>
                    فیلتر
                </v-btn>
                <v-btn rounded v-else color="red" dark @click="filter=!filter">
                    x
                    بستن فیلتر ها
                </v-btn>
            </h4>
            <v-skeleton-loader v-if="loading" type="article@5">
            </v-skeleton-loader>
            <div v-else>
                <div v-if="items.length<1">
                    هیچ هزینه ای وجود ندارد
                </div>
            </div>


            <div>
                <v-chip @click="chipClick('')" :class="[(type===''?'selected':''),'chip']"
                        v-if="factor.all>0" color="brown" dark small>
                    <span v-if="type===''">x</span>
                    همه
                    {{factor.all|digit}}
                </v-chip>
                <v-chip @click="chipClick('salary')" :class="[(type==='salary'?'selected':''),'chip']"
                        v-if="factor.salary>0" color="red" dark small>
                    <span v-if="type==='salary'">x</span>
                    حقوق
                    {{factor.salary|digit}}
                </v-chip>

                <v-chip @click="chipClick('rent')" :class="[(type==='rent'?'selected':''),'chip']"
                        v-if="factor.rent>0" color="gray" class="chip" small>
                    <span v-if="type==='rent'">x</span>

                    اجاره

                    {{factor.rent|digit}}
                </v-chip>
                <v-chip @click="chipClick('ghabz')" :class="[(type==='ghabz'?'selected':''),'chip']"
                        v-if="factor.ghabz>0" color="warning" class="chip" small>
                    <span v-if="type==='ghabz'">x</span>

                    قبوض
                    {{factor.ghabz|digit}}
                </v-chip>

   <v-chip @click="chipClick('cost')" :class="[(type==='cost'?'selected':''),'chip']"
                        v-if="factor.cost>0" color="warning" class="chip" small>
                    <span v-if="type==='cost'">x</span>

                    هزینه های جاری
                    {{factor.cost|digit}}
                </v-chip>




            </div>


            <div v-for="(item,index) in items" :key="index">
                <CostItem  @get="send" :item="item"></CostItem>
            </div>


            <div class="text-center">
                <v-pagination

                        @input="pagination"
                        v-model="page"
                        :length="Math.ceil(count/10)"
                        circle
                ></v-pagination>
            </div>


        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import {mdiCalendar, mdiFilter} from '@mdi/js'
    import VuePersianDatetimePicker from 'vue-persian-datetime-picker'
    import moment from 'moment-jalaali'
    import AWN from "awesome-notifications";
    import CostItem from "@/components/Finance/CostItem";
    import AddCost from "@/components/Finance/AddCost";


    export default {
        watch: {
            '$route'() {
                this.loading = true;
                this.page = this.$route.query.page;
                this.getQuery();
                this.send();
            }
        },

        created() {
            if (!this.page) {
                this.page = 1;
            }
            this.getQuery();
            this.today = moment.unix(Date.now() / 1000).locale('fa').format('jYYYY/jMM/jDD');
            this.send();
        },
        methods: {
            getQuery() {
                this.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
                this.type = this.$route.query.type;
                this.search = this.$route.query.search;
                this.DateEnd = this.$route.query.DateEnd;
                this.DateBegin = this.$route.query.DateBegin;
            },
            sendFilter() {
                this.page = 1;
                this.$router.push({query: this.Query});
                this.send();
            },


            chipClick(type) {

                if (this.type === type) {
                    this.type = ''
                } else {
                    this.type = type;
                }
                this.sendFilter();
            },


            send() {
                this.loading = true;
                this.ServerRequest().then(response => {
                    this.items = response.data.data.data;
                    this.count = response.data.data.count;
                    this.factor = response.data.data.costSummery;
                    this.loading = false;
                });
            },

            ServerRequest() {
                return axios.get(this.$store.state.admin + 'cost', {params: this.Query});
            },


            pagination() {
                this.$router.push({query: this.Query})
                this.filter = false;
            }
        },

        computed: {
            Admin() {
                return this.$store.state.adminRole;
            },
            Query() {
                return {
                    page: this.page ? this.page : 1,
                    type: (this.type ? this.type : ''),
                    search: (this.search ? this.search : ''),
                    DateEnd: (this.DateEnd ? this.DateEnd : ''),
                    DateBegin: (this.DateBegin ? this.DateBegin : ''),
                };
            }
        },
        data() {
            return {
                interval: null,
                notify: new AWN(this.$store.state.NotifyOption),
                allOrder: this.$route.params.allOrder,
                filter: false,
                factor: {
                    all:0,
                    salary: 0,
                    rent: 0,
                    ghabz: 0,
                    cost: 0,

                },
                icon: {mdiCalendar, mdiFilter},
                today: null,
                support: null,
                search: null,
                type: '',
                alltype: [{
                    text: 'حقوق',
                    value: 'salary'
                }, {
                    text: 'کرایه مکان',
                    value: 'rent'
                }, {
                    text: 'قبوض',
                    value: 'ghabz'
                }, {
                    text: 'هزینه جاری',
                    value: 'cost'
                }],
                Customer: '',
                Request: '',
                City: '',
                tell: '',
                DateBegin: '',

                DateEnd: '',
                loading: true,
                page: parseInt(this.$route.query.page),
                count: 0,
                userId: this.$store.state.userId,
                items: null,
            }
        }
        ,
        components: {
            AddCost,
            CostItem,

            datePicker: VuePersianDatetimePicker
        }
    }
</script>

<style scoped>

    .allItems {
        margin: 20px auto;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }


    .filters {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 600px;
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    .selected {
        height: 2.5em;
        border: solid #ccc 3px !important;
        box-shadow: 0px 0px 3px #000;
    }

    .chip {
        margin: 3px;
        text-align: right;
    }


    .filterField {
        width: 180px !important;
    }

    .titles {
        text-align: right;
    }

    .main {
        margin-top: 20px;
    }

    .titles {
        margin-right: 20px;
    }

    .sebMenu {
        background-color: #f5f5f5;
        box-shadow: 0px 0px 3px #ccc;
    }
</style>