import Vue from 'vue'
import Router from 'vue-router'


import DegreeList from "@/components/Users/DegreeList";
import DegreeInfo from "@/components/Users/Profile/DegreeInfo";
import Cost from "@/components/Finance/Cost";
import Configs from "@/components/Setting/Configs";
import Config from "@/components/Setting/Config";
import CreateProvince from "@/components/city/CreateProvince";
import CreateCity from "@/components/city/CreateCity";
import CityList from "@/components/city/CityList";
import CityService from "@/components/city/CityService";
import EditCity from "@/components/city/EditCity";
import EditProvince from "@/components/city/EditProvince";
import Agents from "@/components/Users/Agents";


const FinanceStatistic = resolve => {
    require.ensure(['@/components/Finance/FinanceStatistic'], () => {
        resolve(require('@/components/Finance/FinanceStatistic'));
    });
};


const EditUser = resolve => {
    require.ensure(['@/components/Users/Profile/EditUser'], () => {
        resolve(require('@/components/Users/Profile/EditUser'));
    });
};


const EditPost = resolve => {
    require.ensure(['@/components/Posts/EditPost'], () => {
        resolve(require('@/components/Posts/EditPost'));
    });
};
const AddPost = resolve => {
    require.ensure(['@/components/Posts/AddPost'], () => {
        resolve(require('@/components/Posts/AddPost'));
    });
};
const Factors = resolve => {
    require.ensure(['@/components/Finance/Factors'], () => {
        resolve(require('@/components/Finance/Factors'));
    });
};
const AllOrders = resolve => {
    require.ensure(['@/components/Orders/AllOrders'], () => {
        resolve(require('@/components/Orders/AllOrders'));
    });
};
const Search = resolve => {
    require.ensure(['@/components/Orders/Search'], () => {
        resolve(require('@/components/Orders/Search'));
    });
};
const AddOrder = resolve => {
    require.ensure(['@/components/Orders/AddOrder'], () => {
        resolve(require('@/components/Orders/AddOrder'));
    });
};
const OrderDetail = resolve => {
    require.ensure(['@/components/Orders/OrderDetail'], () => {
        resolve(require('@/components/Orders/OrderDetail'));
    });
};
const Cats = resolve => {
    require.ensure(['@/components/Data/Cats'], () => {
        resolve(require('@/components/Data/Cats'));
    });
};
const Cat = resolve => {
    require.ensure(['@/components/Data/Cat/Cat'], () => {
        resolve(require('@/components/Data/Cat/Cat'));
    });
};
const Statistic = resolve => {
    require.ensure(['@/components/Orders/Statistic'], () => {
        resolve(require('@/components/Orders/Statistic'));
    });
};
const Providers = resolve => {
    require.ensure(['@/components/Users/Providers'], () => {
        resolve(require('@/components/Users/Providers'));
    });
};
const Services = resolve => {
    require.ensure(['@/components/Data/Services'], () => {
        resolve(require('@/components/Data/Services'));
    });
};
const Bloggers = resolve => {
    require.ensure(['@/components/Users/Bloggers'], () => {
        resolve(require('@/components/Users/Bloggers'));
    });
};
const Customers = resolve => {
    require.ensure(['@/components/Users/Customers'], () => {
        resolve(require('@/components/Users/Customers'));
    });
};
const TellCustomers = resolve => {
    require.ensure(['@/components/Users/TellCustomers'], () => {
        resolve(require('@/components/Users/TellCustomers'));
    });
};
const PostComments = resolve => {
    require.ensure(['@/components/Comments/PostComments'], () => {
        resolve(require('@/components/Comments/PostComments'));
    });
};
const OrderComments = resolve => {
    require.ensure(['@/components/Comments/OrderComments'], () => {
        resolve(require('@/components/Comments/OrderComments'));
    });
};
const Supporter = resolve => {
    require.ensure(['@/components/Users/Supporter'], () => {
        resolve(require('@/components/Users/Supporter'));
    });
};
const Admins = resolve => {
    require.ensure(['@/components/Users/Admins'], () => {
        resolve(require('@/components/Users/Admins'));
    });
};
const EditOrder = resolve => {
    require.ensure(['@/components/Orders/EditOrder'], () => {
        resolve(require('@/components/Orders/EditOrder'));
    });
};
const AddService = resolve => {
    require.ensure(['@/components/Data/Service/AddService'], () => {
        resolve(require('@/components/Data/Service/AddService'));
    });
};
const Service = resolve => {
    require.ensure(['@/components/Data/Service/Service'], () => {
        resolve(require('@/components/Data/Service/Service'));
    });
};
const EditService = resolve => {
    require.ensure(['@/components/Data/Service/EditService'], () => {
        resolve(require('@/components/Data/Service/EditService'));
    });
};
const EditCat = resolve => {
    require.ensure(['@/components/Data/Cat/EditCat'], () => {
        resolve(require('@/components/Data/Cat/EditCat'));
    });
};
const AddCat = resolve => {
    require.ensure(['@/components/Data/Cat/AddCat'], () => {
        resolve(require('@/components/Data/Cat/AddCat'));
    });
};
const ProfileDetail = resolve => {
    require.ensure(['@/components/Users/Profile/ProfileDetail'], () => {
        resolve(require('@/components/Users/Profile/ProfileDetail'));
    });
};

const Index = resolve => {
    require.ensure(['@/components/Index.vue'], () => {
        resolve(require('@/components/Index.vue'));
    });
};

const Profile = resolve => {
    require.ensure(['@/components/Profile.vue'], () => {
        resolve(require('@/components/Profile.vue'));
    });
};
const AllPosts = resolve => {
    require.ensure(['@/components/Posts/AllPosts.vue'], () => {
        resolve(require('@/components/Posts/AllPosts.vue'));
    });
};
const EditProfile = resolve => {
    require.ensure(['@/components/EditProfile.vue'], () => {
        resolve(require('@/components/EditProfile.vue'));
    });
};
const Add = resolve => {
    require.ensure(['@/components/Add.vue'], () => {
        resolve(require('@/components/Add.vue'));
    });
};

Vue.use(Router);


export default new Router({


    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        if (to.hash) {
            return {selector: to.hash};
        }
        return {x: 0, y: 0};
    },
    routes: [
        {
            path: '/',
            name: 'Index',
            component: Index
        }, {
            path: '/profile',
            name: 'Profile',
            component: Profile,
        },
        {
            path: '/provider',
            name: 'Provider',
            component: Providers,
        }, {
            path: '/DegreeList',
            name: 'DegreeList',
            component: DegreeList,
        }, {
            path: '/postcomments',
            name: 'PostComments',
            component: PostComments,
        }, {
            path: '/ordercomments',
            name: 'OrderComments',
            component: OrderComments,
        }, {
            path: '/supporter',
            name: 'Supporter',
            component: Supporter,
        }, {
            path: '/agents',
            name: 'Agents',
            component: Agents,
        }, {
            path: '/admins',
            name: 'Admins',
            component: Admins,
        }, {
            path: '/bloggers',
            name: 'Bloggers',
            component: Bloggers,
        }, {
            path: '/customers',
            name: 'Customers',
            component: Customers,
        }, {
            path: '/tellcustomers',
            name: 'TellCustomers',
            component: TellCustomers,
        }, {
            path: '/services',
            name: 'Services',
            component: Services,
        },
        {
            path: '/factors/:providerId?/:supportId?',
            name: 'Factors',
            component: Factors,
        }, {
            path: '/statistic',
            name: 'Statistic',
            component: Statistic,
        }, {
            path: '/cost',
            name: 'Cost',
            component: Cost,
        }, {
            path: '/financestatistic',
            name: 'FinanceStatistic',
            component: FinanceStatistic,
        }, {
            path: '/cats',
            name: 'Cats',
            component: Cats,
        }, {
            path: '/cat/:id',
            name: 'cat',
            component: Cat,
        }, {
            path: '/editcat/:id',
            name: 'EditCat',
            component: EditCat,
        }, {
            path: '/addcat',
            name: 'AddCat',
            component: AddCat,
        }, {
            path: '/editpost/:id',
            name: 'EditPost',
            component: EditPost,
        }, {
            path: '/profiledetail/:id',
            name: 'ProfileDetail',
            component: ProfileDetail,
        }, {
            path: '/edituser/:id',
            name: 'EditUser',
            component: EditUser,
        }, {
            path: '/service/:id',
            name: 'Service',
            component: Service,
        }, {
            path: '/editorder/:id',
            name: 'EditOrder',
            component: EditOrder,
        }, {
            path: '/editservice/:id',
            name: 'EditService',
            component: EditService,
        }, {
            path: '/addorder/:id',
            name: 'addorder',
            component: AddOrder,
        }, {
            path: '/orderdetail/:id',
            name: 'orderdetail',
            component: OrderDetail,
        }, {
            path: '/degreeinfo/:id',
            name: 'degreeinfo',
            component: DegreeInfo,
        }, {
            path: '/addpost',
            name: 'AddPost',
            component: AddPost,
        }, {
            path: '/addservice',
            name: 'AddService',
            component: AddService,
        }, {
            path: '/search',
            name: 'search',
            component: Search,
        }, {
            path: '/allposts',
            name: 'AllPosts',
            component: AllPosts,
        }, {
            path: '/allorders/:providerId?',
            name: 'AllOrders',
            component: AllOrders,
        }, {
            path: '/editprofile',
            name: 'EditProfile',
            component: EditProfile,
        }, {
            path: '/add',
            name: 'Add',
            component: Add,
        }, {
            path: '/configs',
            name: 'Configs',
            component: Configs,
        }, {
            path: '/config/:id',
            name: 'Config',
            component: Config,
        },

        {
            path: '/createprovince',
            name: 'CreateProvince',
            component: CreateProvince,
        }, {
            path: '/createcity',
            name: 'CreateCity',
            component: CreateCity,
        }, {
            path: '/citylist',
            name: 'CityList',
            component: CityList,
        }, {
            path: '/cityservice/:id',
            name: 'CityService',
            component: CityService,
        }, {
            path: '/editcity/:id',
            name: 'EditCity',
            component: EditCity,
        }, {
            path: '/editprovince/:id',
            name: 'EditProvince',
            component: EditProvince,
        },

    ]
})
