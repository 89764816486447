<template>


    <div class="dateNow">
        <small class="dateNowText">
            {{today}}
        </small>
        <small class="dateNowTextTime">
            {{time}}
        </small>

    </div>


</template>
<script>

    import moment from "moment-jalaali";


    export default {


        created() {
            this.refresh();
        },

        data: () => ({
            today: moment.unix(Date.now() / 1000).locale('fa').format('dddd  jD jMMMM jYYYY'),
            time: moment.unix(Date.now() / 1000).locale('fa').format('HH:mm:ss'),

        }),


        methods: {
            refresh() {
                const self = this;
                    setInterval(function () {
                        self.today = moment.unix(Date.now() / 1000).locale('fa').format('dddd  jD jMMMM jYYYY')
                        self.time = moment.unix(Date.now() / 1000).locale('fa').format('HH:mm:ss')
                    }, 1000);
            }
            ,
        }
        ,
        components: {}
        ,
        computed: {}
        ,
    }
</script>

<style>

    @media screen and (max-width: 500px) {
        .dateNow {
            left: 0px !important;
            bottom: 0px !important;
            top: auto !important;
            transform: rotate(0deg) !important;
        }

        .dateNowText {
            width: 500px !important;

        }

    }


    .dateNow {
        z-index: 100 !important;
        transform: rotate(90deg);
        position: fixed;
        width: 250px;
        left: -110px;
        top: 50%;
    }

    .dateNowText {
        padding: 5px 10px 30px;
        cursor: pointer;
        background: linear-gradient(#3fbba0, #2c3e50);
        color: #fff;
        box-shadow: 0px 0px 3px rgba(38, 115, 97, 0.49);
        border-radius: 0px 10px 0px 0px ;
        height: 100px;
        transition: 0.5s;
    }
    .dateNowTextTime {
        padding: 5px 10px 30px;
        cursor: pointer;
        background: linear-gradient(#bb5741, #502a29);
        color: #fff;
        box-shadow: 0px 0px 3px rgba(115, 63, 27, 0.49);
        border-radius: 10px 0px 0px 0px ;
        height: 100px;
        transition: 0.5s;
    }

    .dateNowText:hover {
        background: linear-gradient(#36bbba, #293550);

    }


</style>