<template>
    <div>


        <v-card flat :loading="loading" :disabled="loading" class="box-city">
            <h3 style="margin: 20px!important;text-align: right">

                خدمات قابل ارائه برای شهر {{data.city.name}}


                <v-btn color="primary" to="/citylist" x-small>برگشت</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="orange" :to="{name:'EditCity',params:{id:id}}" x-small>ویرایش</v-btn>
            </h3>
            <template v-if="enable">
                <v-alert dark color="success">همه ی خدمات برای شهر
                    {{data.city.name}}
                    فعال هستند
                    <v-btn @click="disableAll" color="red" dark>غیر فعال کردن همه</v-btn>
                </v-alert>

                <v-alert v-if="data.disable&&data.disable.length>0">
                    به جز خدمات
                    <v-chip color="red" dark v-for="(item,i) in data.disable" :key="i" close
                            @click:close="delcity(item.id)">{{item.title}}
                    </v-chip>
                    که در شهر {{data.city.name}} فعال نمی باشند
                </v-alert>
            </template>
            <template v-else>
                <v-alert dark color="red">همه ی خدمات برای شهر
                    {{data.city.name}}
                    غیر فعال هستند
                    <v-btn color="success" @click="enableAll" dark> فعال کردن همه</v-btn>
                </v-alert>

                <v-alert v-if="data.enabled&&data.enabled.length>0">


                    به جز خدمات
                    <v-chip color="success" dark v-for="(item,i) in data.enabled" :key="i" close
                            @click:close="delcity(item.id)">{{item.title}}
                    </v-chip>
                    که در شهر {{data.city.name}} فعال می باشند
                </v-alert>


            </template>

            <v-autocomplete filled outlined label="شهر استثناً را وارد کنید" v-model="modelService"
                            @change="addCityService(modelService)" :items="services" item-text="title"
                            item-value="id"></v-autocomplete>


            <v-alert style="text-align: justify;" color="#fa5">
                راهنما:

                میتوانید از دو روش استفاده کنید
                <ol style="padding: 20px">
                    <li>یا یک شهر را در تمام سایت غیر فعال کنید و شهر هایی را استثنائاً فعال کنید</li>
                    <li>یا یک شهر را در تمام سایت فعال کنید و شهر هایی را استثنائاً غیرفعال کنید</li>
                </ol>
            </v-alert>
        </v-card>


    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "CityService",
        computed: {
            enable() {
                return !this.data.disableAll
            }
        },

        created() {
            this.loading = true;
            axios.get(this.$store.state.admin + 'cityservice', {params: {id: this.id}}).then(response => {
                this.data = response.data.data;
                axios.get(this.$store.state.admin + 'allservices', {
                    params: {
                        enable: this.data.disableAll,
                        cityId: this.id
                    }
                }).then(response => {
                    this.services = response.data.data;
                    this.loading = false;
                });
            });


        },

        methods: {

            disableAll() {
                this.loading = true;
                axios.post(this.$store.state.admin + 'disablecity', {
                    cityId: this.id,
                }).then(response => {
                    this.data = response.data.data.data;
                    this.services = response.data.data.services;
                    this.loading = false;
                });
            }, enableAll() {
                this.loading = true;
                axios.post(this.$store.state.admin + 'enablecity', {
                    cityId: this.id,
                }).then(response => {
                    this.data = response.data.data.data;
                    this.services = response.data.data.services;
                    this.loading = false;
                });
            },
            delcity(id) {
                this.loading = true;
                axios.post(this.$store.state.admin + 'delcityservice', {
                    id: id,
                    cityId: this.id,
                    enable: this.data.disableAll
                }).then(response => {
                    this.data = response.data.data.data;
                    this.services = response.data.data.services;
                    this.modelService = null;
                    this.loading = false;
                });
            },
            addCityService(serviceId) {
                this.loading = true;
                axios.post(this.$store.state.admin + 'addcityservice', {
                    serviceId: serviceId,
                    cityId: this.id,
                    enable: this.data.disableAll
                }).then(response => {
                    this.data = response.data.data.data;
                    this.services = response.data.data.services;
                    this.modelService = null;
                    this.loading = false;
                });
            }
        },
        data() {
            return {
                modelService: null,
                data: [],
                id: this.$route.params.id,
                services: [],
                loading: false,
            }
        }
    }
</script>

<style scoped>
    .box-city {
        width: 600px;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding: 20px !important;
        border-radius: 30px !important;
        box-shadow: 0 10px 10px #ccc !important;
    }
</style>