<template>
    <div class="text-center ma-2">
        <v-snackbar right v-model="$store.state.notify.is">

            {{ text }}
            <v-btn
                    color="yellow"
                    text
                    @click="$store.state.notify.is = false"
            >
                خب
            </v-btn>
        </v-snackbar>

    </div>
</template>
<script>
    import { VSnackbar,VBtn } from 'vuetify/lib'
    export default {
        data: () => ({}),


        components:{VBtn,VSnackbar},
        computed: {
            text() {
                return this.$store.state.notify.text;
            },
        },
    }
</script>