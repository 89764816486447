import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store';
import vuetify from '@/plugins/vuetify' // path to vuetify export
import { fn } from '@/assets/js/fn';
import VuePersianDatetimePicker from 'vue-persian-datetime-picker';


router.beforeEach((to, from, next) => {
    store.state.loading = true;

    if (store.state.userId > 0) {
        if (!(store.state.adminRole>0) && store.state.adminRoutes.includes(to.name)) {
            next('/');
            store.state.loading = false;
        } else {
            next();
        }
    } else {
        if (to.path === '/add') {
            next();
        } else {
            router.push('/add')
        }
    }
});

router.afterEach(() => {
    store.state.loading = false;
});
Vue.prototype.$fn = fn;
Vue.filter('digit', function (number) {
    if(!isNaN(number)) {
        return Number(number).toLocaleString();
    }else {
        return number;
    }
});



new Vue({
    router,
    store,
    vuetify,
    'date-picker': VuePersianDatetimePicker,
    render: h => h(App),
}).$mount('#app');
