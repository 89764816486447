<template>
    <div>

        <div class="container-fluid sebMenu">
            <transition name="slide">
                <div :key="1" class="filters" v-show="filter">

                    <div class="filterField">
                        <v-text-field clearable :loading="loading" v-model="Name" @keydown.enter="sendFilter" label="نام یا نام خانوادگی"></v-text-field>
                    </div>

                    <div class="filterField">
                        <v-checkbox :loading="loading" color="success" false-value="" @change="sendFilter" true-value="1" v-model="degree" label="تایید شده">
                        </v-checkbox>
                    </div>
                    <div class="filterField">
                        <v-checkbox :loading="loading" false-value="" color="orange" @change="sendFilter" true-value="1" v-model="pending" label="در دست بررسی">
                        </v-checkbox>
                    </div> <div class="filterField">
                        <v-checkbox :loading="loading" false-value="" color="red" @change="sendFilter" true-value="1" v-model="notConfirmed" label="رد شده">
                        </v-checkbox>
                    </div>


                    <div class="filterField">
                        <v-btn color="blue" class="filterBtn" dark rounded :loading="loading" @click="sendFilter()">
                            اعمال فیلتر
                        </v-btn>
                    </div>


                </div>
            </transition>


        </div>


        <div class="main small-box container">
            <h4 class="titles">استادکاران
                {{count?'('+count+')':''}}
                <v-btn rounded v-if="!filter" color="green" dark @click="filter=!filter">
                    <v-icon>{{icon.mdiFilter}}</v-icon>
                    فیلتر
                </v-btn>
                <v-btn rounded v-else color="red" dark @click="filter=!filter">
                    x
                    بستن فیلتر ها
                </v-btn>

            </h4>

            <v-select dense :loading="loading" small class="sort" clearable @click:clear="sort=''" @change="sendFilter"
                      v-model="sort" :items="Allsort" item-text="text"
                      item-value="value" label="مرتب سازی"></v-select>
            <v-skeleton-loader v-if="loading" type="article@5">
            </v-skeleton-loader>
            <template v-else>
                <div>
                    <div v-if="items.length<1">
                        هیچ متخصصی با این مشخصات وجود ندارد
                    </div>
                </div>
                <div v-for="(item,index) in items" :key="index">
                    <DegreeItem :item="item"></DegreeItem>
                </div>
            </template>
            <div class="text-center">
                <v-pagination
                        @input="pagination"
                        v-model="page"
                        :length="Math.ceil(count/20)"
                        circle
                ></v-pagination>
            </div>


        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import DegreeItem from "@/components/Users/DegreeItem";
    import {mdiCalendar, mdiFilter} from '@mdi/js'
    import moment from 'moment-jalaali'
    import AWN from "awesome-notifications";


    export default {
        watch: {
            '$route'() {
                this.loading = true;
                this.getQuery();
                this.send();
            }
        },

        created() {
            if (!this.page) {
                this.page = 1;
            }
            this.getQuery();
            this.today = moment.unix(Date.now() / 1000).locale('fa').format('jYYYY/jMM/jDD');
            this.send();
        },

        computed: {
            Query(){
                return {
                    page: this.page?this.page:1,
                    Name: (this.Name ? this.Name : ''),
                    sort: (this.sort ? JSON.stringify(this.sort) : ''),
                    degree: (this.degree ? this.degree : ''),
                    pending: (this.pending ? this.pending : ''),
                    notConfirmed: (this.notConfirmed ? this.notConfirmed : ''),
                };
            }
        },

        methods: {

            getQuery() {
                 this.page = this.$route.query.page?parseInt(this.$route.query.page):1;
                this.Name = this.$route.query.Name;
                this.degree = this.$route.query.degree;
                this.pending = this.$route.query.pending;
                this.sort = this.$route.query.sort ? JSON.parse(this.$route.query.sort) : '';
                this.notConfirmed = this.$route.query.notConfirmed;
            },

            sendFilter() {
                this.page = 1;
                this.$router.push({query: this.Query});
                this.send();
            },
            send() {
                this.loading = true;
                axios.get(this.$store.state.admin + 'degree', {params: this.Query}).then(response => {
                    this.items = response.data.data.items;
                    this.deCashMount = response.data.data.deCash;
                    this.count = response.data.data.count;
                    this.loading = false;
                })
            },

            pagination() {
                this.$router.push({query: this.Query})
            }
        },


        data() {
            return {
                sort: '',
                Allsort: [
                    {text: 'جدید ترین', value: {field: 'updateTime', sort: 3},},
                    {text: 'قدیمی ترین', value: {field: 'updateTime', sort: 4},}
                ],
                notify: new AWN(this.$store.state.NotifyOption),
                filter: false,
                icon: {mdiCalendar, mdiFilter},
                degree: '',
                Name: '',
                notConfirmed: '',
                pending: '',
                loading: true,
                page: parseInt(this.$route.query.page),
                count: 0,
                userId: this.$store.state.userId,
                items: null,
            }
        }
        ,
        components: {
            DegreeItem,
        }
    }
</script>

<style scoped>

    .allItems {
        margin: 20px auto;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }


    .filters {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 600px;
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    .filterField {
        width: 180px !important;
    }

    .titles {
        text-align: right;
    }

    .main {
        margin-top: 20px;
    }

    .titles {
        margin-right: 20px;
    }

    .sort {
        width: 90%;
        margin-right: auto;
        margin-left: auto;
    }


</style>